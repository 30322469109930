import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, TypeMediaFileEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogDeliveryApplicationTicketEditorComponent } from 'app/dialog/dialog-delivery-application-ticket-editor/dialog-delivery-application-ticket-editor.component';
import { DialogDuplicateApplicationDesignComponent } from 'app/dialog/dialog-duplicate-application-design/dialog-duplicate-application-design.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTranslateTicketComponent } from 'app/dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { Common } from 'app/model/entity/common';
import { Image as ImageApplicaiton } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { AppDetailDTO } from 'app/model/entity/ticket-editor/dto/app-detail-DTO';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { PreviewTabApplicationComponent } from 'app/module/ticket-editor/ticket-editor-tab-application/preview-tab-application/preview-tab-application.component';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { ApplicationService } from 'app/service/ticket-editor/application.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { AppDesignDTO } from './../../../model/entity/ticket-editor/dto/app-design-DTO';
@Component({
  selector: 'ticket-editor-tab-application',
  templateUrl: './ticket-editor-tab-application.component.html',
  styleUrls: ['./ticket-editor-tab-application.component.scss']
})
export class TicketEditorTabApplicationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('appName') appNameElementRef: ElementRef;
  @ViewChild('nameOfBusinessOrSupplier') nameOfBusinessOrSupplierElementRef: ElementRef;
  @ViewChild(PreviewTabApplicationComponent) previewComponent: PreviewTabApplicationComponent;
  @ViewChild('designName') designNameElementRef: ElementRef;
  @ViewChild('ShortNameOfTheApp') shortNameOfTheAppElementRef: ElementRef;
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  @Input() mapImageEnvironmentVariables: Map<string, number>;
  @Output()
  public appId = new EventEmitter<string>();
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component
  @Output() languageTranslationAppSelectedOutPut: EventEmitter<any> = new EventEmitter<any>();
  @Output() listAppOutPut: EventEmitter<any> = new EventEmitter<any>();
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  LENGTH_CHARACTER_CODE_COLOR = 6; // Length char code color
  subscriptions: Array<Subscription> = new Array<Subscription>();
  appSelected: ApplicationDTO; // app Selected
  appDesignSelected: AppDesignDTO; // app Selected
  listAppDesignSelected: Array<AppDesignDTO> = new Array<AppDesignDTO>(); // list App Detail find by app selected
  appDetailSelected: AppDetailDTO; // app Deatail Selected
  designNameEdit: string; // design name edit
  listApp: ApplicationDTO[] = []; // list Application
  isEnlargePreview: boolean = false; // true if preview on
  isPlay: boolean; //true if play preview
  isFormEditAppDetail: boolean = false; // true if edit app detail
  shortNameOfTheApp: string; // Data short name of the application
  topPhoto: string;
  mainColor: string; // Data main color
  subColor: string; // Data sub Color
  backgroundColor: string; // Data background Color
  borderColor: string; // Data border Color
  inActiveColor: string; // Data inactive color
  isReponsive: boolean; //true if is reponsive
  languageSelected: string;
  focusOn: string;
  displaySize = [
    { title: 'iPhoneSE', width: '375px', height: '667px' },
    { title: 'iPhone14', width: '390px', height: '844px' },
    { title: 'iPhone14 Pro Max', width: '428px', height: '926px' },
    { title: 'iPhone13 mini', width: '375px', height: '812px' },
    { title: 'Galaxy A51', width: '412px', height: '914px' },
    { title: 'Galaxy S8', width: '360px', height: '740px' },
    { title: 'Pixel 5', width: '393px', height: '851px' },
    { title: 'Responsive', width: '375px', height: '667px' }
  ];
  colorInputs = [
    { color: 'mainColor', isCheckInput: false },
    { color: 'subColor', isCheckInput: false },
    { color: 'backgroundColor', isCheckInput: false },
    { color: 'borderColor', isCheckInput: false },
    { color: 'inActiveColor', isCheckInput: false }
  ];
  displaySizeSelected = this.displaySize[0];
  widthReponsive: number = 375; // data input width reponsive
  heightReponsive: number = 667; // data input height reponsive
  /**
   * list file data
   */
  fileData: any;
  /**
   * header column application details
   */
  headerColumnApplicationDetails: any = [
    {
      label: 'ticket-editor.short-name-of-the-app',
      field: 'shortNameOfTheApp'
    },
    {
      label: 'ticket-editor.top-photo',
      field: 'topPhoto'
    },
    {
      label: 'ticket-editor.main-color',
      field: 'mainColor'
    },
    {
      label: 'ticket-editor.sub-color',
      field: 'subColor'
    },
    {
      label: 'ticket-editor.background-color',
      field: 'backgroundColor'
    },
    {
      label: 'ticket-editor.border-color',
      field: 'borderColor'
    },
    {
      label: 'ticket-editor.inActive-color',
      field: 'inActiveColor'
    },
    {
      label: 'ticket-editor.set-language',
      field: 'supportedLanguageTranslation'
    }
  ];

  /**
   * data Top Photo Clone
   */
  dataTopPhotoClone: string;

  /**
   * data Top Photo Clone
   */
  dataTopPhotoUrlClone: string;

  /**
   * header column list app
   */
  headerColumnListApp: any = [
    { headerName: 'ticket-editor.category', property: 'category' },
    { headerName: 'ticket-editor.design-name', property: 'design-name' }
  ];
  listAppDesign = []; // List app design
  isDuplicateApp: boolean = false; // Check is form duplicate application
  isEditFormApp: boolean = false; // Check is form application

  /**
   * Constant's component
   */
  private readonly FILE_MEDIA_OBJECT = 1;
  private readonly IMAGE_NAME_DROP_MEDIA = 'application';
  private readonly FILE_ATTRIBUTE = 'file';
  private readonly NAME_ATTRIBUTE = 'name';
  private readonly URL_ATTRIBUTE = 'url';

  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPEG];
  acceptFileTypes: string = '.jpg, .png, .jpeg';
  languages: any = new Array<any>();
  languageKey: string;
  /**
   * common object
   */
  private commonObject: Common;
  labelAddImage: string; //label Add Image

  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(
    private menuActionService: MenuActionService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private dataService: DataService,
    private dialogService: DialogService,
    public datepipe: DatePipe,
    private mediaService: MediaService,
    private commonService: CommonService
  ) {
    this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    this.subscriptions.push(
      // Action add
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          this.addNewApplication();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.editApplication();
          }
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.deleteApplication();
          }
        }
      }),
      // Action duplicate
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.duplicateApp();
          }
        }
      }),
      // Action add design
      this.menuActionService.actionAddAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplication()) {
            this.addAppDesign();
          }
        }
      }),
      // Action edit design
      this.menuActionService.actionEditAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.editAppDesign();
          }
        }
      }),
      // Action delete design
      this.menuActionService.actionDeleteAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.deleteAppDesign();
          }
        }
      }),
      // Action duplicate design
      this.menuActionService.actionDuplicateAppDesign.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.duplicateAppDesign();
          }
        }
      }),
      // Action edit detail
      this.menuActionService.actionEditDetail.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          if (this.checkSelectApplicationDesign()) {
            this.editApplicationDetail();
          }
        }
      }),
      // Action delivery
      this.menuActionService.actionDelivery.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.APPLICATION_ENUM) {
          this.deliveryApp();
        }
      })
    );

    this.commonObject = commonService.getCommonObject();
    this.translateService.onLangChange.subscribe(() => {
      this.languageKey = this.commonService.getCommonObject().setting?.language;
      this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
      this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    });
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonService.getCommonObject()?.setting?.language;
    await this.getAllApplication();
    this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
    if (this.listApp && this.listApp.length > 0) {
      this.selectApplication(this.listApp[Constant.FIRST_ELEMENT_INDEX]);
    }
    this.listAppOutPut.emit(this.listApp);
  }

  ngAfterViewInit(): void {
    this.setDataPreview();
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Set data for preview application
   */
  setDataPreview(str?: string, e?): void {
    if (str && e.color) {
      this[str] = e.color;
      this.convertColorToRgba(e, str);
    }
    const data = {
      topPhoto: this.appDetailSelected?.topPhotoUrl,
      mainColor: this.mainColor && this.mainColor.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.mainColor.toLocaleUpperCase() : '',
      subColor: this.subColor && this.subColor?.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.subColor.toLocaleUpperCase() : '',
      backgroundColor:
        this.backgroundColor && this.backgroundColor?.length > this.LENGTH_CHARACTER_CODE_COLOR
          ? this.backgroundColor.toLocaleUpperCase()
          : '',
      borderColor:
        this.borderColor && this.borderColor?.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.borderColor.toLocaleUpperCase() : '',
      inActiveColor:
        this.borderColor && this.inActiveColor?.length > this.LENGTH_CHARACTER_CODE_COLOR ? this.inActiveColor.toLocaleUpperCase() : ''
    };
    if (this.previewComponent !== undefined) {
      this.previewComponent.setDataPreview(data);
    }
  }

  /**
   * convertColorToRgba
   * @param event
   * @param prop
   */
  convertColorToRgba(event: any, prop: string): void {
    // Remove the '#' if it's present
    let hexColor = event.color.replace('#', '');

    // Convert opacity to a valid range (0 to 1)
    const validOpacity = Math.max(0, Math.min(1, +event.opacity));

    // Convert the hex color to RGB
    const bigint = parseInt(hexColor, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Convert the components to hexadecimal and pad with zeros if needed
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    const hexOpacity = Math.round(validOpacity * 255)
      .toString(16)
      .padStart(2, '0');

    // Create the 8-character hex color code
    let hexWithOpacity = `#${hexR}${hexG}${hexB}${hexOpacity}`;
    this[prop] = hexWithOpacity;
  }

  /**
   * Set data for preview application
   *
   * @param widthReponsive
   * @param heightReponsive
   */
  setDisplaySizePreview(widthReponsive: number, heightReponsive: number): void {
    const data = {
      width: widthReponsive,
      height: heightReponsive
    };
    if (this.previewComponent !== undefined) {
      this.previewComponent.setDisplaySizePreview(data);
    }
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.applicationService.findAllApplication().subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  /**
   * Select Application
   * @param app
   * @param appDesign
   */
  selectApplication(app: ApplicationDTO, appDesign?: AppDesignDTO, isCancel?: boolean): void {
    if (this.isEditFormApp || this.isFormEditAppDetail) {
      return;
    }
    this.appSelected = app;
    this.listAppDesignSelected = app.appDesigns;
    this.appDetailSelected = new AppDetailDTO();
    if (this.listAppDesignSelected && this.listAppDesignSelected.length > 0) {
      appDesign = appDesign ? appDesign : this.listAppDesignSelected[0];
      this.selectApplicationDesign(appDesign, isCancel);
    } else {
      this.appDesignSelected = undefined;
      this.appDetailSelected = undefined;
    }
    this.fileData = undefined;
    this.languages = Constant.LANGUAGES_SETTING.filter(e =>
      this.appSelected.supportedLanguageTranslation?.includes(e.translation_language_code)
    );
    this.languageTranslationAppSelectedOutPut.emit(this.appSelected.supportedLanguageTranslation);
    this.appId.emit(this.appSelected.appId);
  }

  selectApplicationDesign(appDesign: AppDesignDTO, isCancel?: boolean, isNewAppDesign?: boolean, event?): void {
    if (
      this.isEditFormApp ||
      this.isFormEditAppDetail ||
      (this.appDesignSelected?.isEdit && !isNewAppDesign) ||
      event?.target?.id === 'checkBoxAppDesign'
    ) {
      return;
    }
    this.appDesignSelected = appDesign;
    if (this.appDesignSelected.id != undefined) {
      this.appDetailSelected = appDesign.appDetailDTO;
      this.isFormEditAppDetail = false;
      if (!isCancel) {
        this.dataTopPhotoClone = _.cloneDeep(this.appDetailSelected.topPhoto);
        this.dataTopPhotoUrlClone = _.cloneDeep(this.appDetailSelected.topPhotoUrl);
      }
    } else {
      this.appDetailSelected = new AppDetailDTO();
    }
    this.mainColor = this.appDetailSelected?.mainColor;
    this.subColor = this.appDetailSelected?.subColor;
    this.backgroundColor = this.appDetailSelected?.backgroundColor;
    this.borderColor = this.appDetailSelected?.borderColor;
    this.inActiveColor = this.appDetailSelected?.inActiveColor;
    this.setDataPreview();
  }

  /**
   * Add new application element
   * @param appName
   */
  addNewApplication(appName?: string, isDuplicate?: boolean): void {
    let listAppHaveNotBeenDelivered = this.listApp?.filter(e => !e?.appId);
    if (listAppHaveNotBeenDelivered && listAppHaveNotBeenDelivered.length > 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.please-delivery'),
          textWithHashtags: listAppHaveNotBeenDelivered.map(e => e.name)
        }
      });
      return;
    }
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    let appNew = new ApplicationDTO(null, null, null, null);

    this.dialogService.showDialog(
      DialogTranslateTicketComponent,
      {
        data: {
          title: this.translateService.instant('menu-ticket-editor.edit.add-application'),
          application: appNew,
          mode: Constant.MODE_ADD
        }
      },
      async result => {
        if (!result) {
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          return;
        }
        await this.getAllApplication();
        this.listAppOutPut.emit(this.listApp);
        this.selectApplication(result);
        this.addAppDesign();
      }
    );
  }

  /**
   * Edit application element
   */
  editApplication(): void {
    if (!this.appSelected) {
      return;
    }
    let application = _.cloneDeep(this.appSelected);
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    this.dialogService.showDialog(
      DialogTranslateTicketComponent,
      {
        data: {
          title: this.translateService.instant('menu-ticket-editor.edit.edit-application'),
          application: application,
          mode: Constant.MODE_EDIT
        }
      },
      async result => {
        if (!result) {
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          return;
        } else {
          let indexApp = this.listApp.findIndex(item => item.id == result?.id);
          this.listApp[indexApp] = result;
          this.appSelected = result;
          this.listApp[indexApp].appDesigns.forEach(e => (e.isSynchronizedDelivery = false));
          if (this.appDesignSelected) {
            this.selectApplication(this.appSelected, this.appDesignSelected);
          } else {
            this.selectApplication(this.appSelected);
          }
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
        }
      }
    );
  }

  /**
   * delete application
   */
  async deleteApplication(): Promise<void> {
    if (!this.appSelected.id) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.confirm-delete-application'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      async result => {
        if (!result) {
          return;
        }
        try {
          // Delete application
          await this.applicationService.deleteApplicationById(this.appSelected.id).toPromise();
          await this.getAllApplication();
          this.listAppOutPut.emit(this.listApp);
          if (!this.listApp || (this.listApp && this.listApp.length == 0)) {
            this.appSelected = undefined;
            this.listAppDesignSelected = undefined;
            this.appDesignSelected = undefined;
            this.appDetailSelected = undefined;
          } else if (this.listApp && this.listApp.length > 0) {
            this.selectApplication(this.listApp[Constant.FIRST_ELEMENT_INDEX]);
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  /**
   * Check select application
   */
  checkSelectApplication(): boolean {
    if (this.appSelected) {
      return true;
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('ticket-editor.choose-app')
      }
    });
    return false;
  }

  /**
   * Check select application
   */
  checkSelectApplicationDesign(): boolean {
    if (this.appDesignSelected) {
      return true;
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('ticket-editor.choose-app-design')
      }
    });
    return false;
  }

  /**
   * add App Design element
   */
  addAppDesign(): void {
    if ((this.appDetailSelected && this.appDetailSelected.isEdit) || (this.appSelected && this.appSelected.isEdit) || !this.appSelected) {
      return;
    }
    this.isEditFormApp = true;
    this.designNameEdit = Constant.EMPTY;
    const appDesign = new AppDesignDTO();
    appDesign.isEdit = true;
    if (!this.listAppDesignSelected) {
      this.listAppDesignSelected = [];
    }
    this.listAppDesignSelected.push(appDesign);
    appDesign.appDetailDTO = new AppDetailDTO();
    this.appDesignSelected = appDesign;
    this.appDetailSelected = appDesign.appDetailDTO;
    this.mainColor = this.appDetailSelected?.mainColor;
    this.subColor = this.appDetailSelected?.subColor;
    this.backgroundColor = this.appDetailSelected?.backgroundColor;
    this.borderColor = this.appDetailSelected?.borderColor;
    this.inActiveColor = this.appDetailSelected?.inActiveColor;
    this.setDataPreview();
    this.selectApplicationDesign(appDesign, false, true);
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
  }

  /**
   * Edit App Design element
   */
  editAppDesign(): void {
    if ((this.appDetailSelected && this.appDetailSelected.isEdit) || (this.appSelected && this.appSelected.isEdit) || !this.appSelected) {
      return;
    }
    this.isEditFormApp = true;
    const index = this.listAppDesignSelected.findIndex(appDesign => appDesign.id == this.appDesignSelected.id);
    this.listAppDesignSelected[index].isEdit = true;
    this.designNameEdit = this.listAppDesignSelected[index].designName;
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
  }

  /**
   * save DesignName
   */
  async saveDesignName(): Promise<void> {
    // validate
    if (!this.validateDesignName(this.designNameEdit)) {
      return;
    }
    this.isEditFormApp = false;
    if (this.appDesignSelected?.id != undefined) {
      delete this.appDesignSelected['isEdit'];
      this.appDesignSelected.designName = this.designNameEdit;
      this.appDesignSelected.isSynchronizedDelivery = false;
      this.applicationService.createAppDesign(this.appDesignSelected).subscribe(res => {
        let indexApp = this.listApp.findIndex(item => item.id == res?.appId);
        let indexAppDesign = this.listApp[indexApp].appDesigns.findIndex(element => element.id == res?.id);
        this.listApp[indexApp].appDesigns[indexAppDesign] = res;
        this.selectApplication(this.appSelected, res);
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      });
    } else {
      const appDesignDTO = new AppDesignDTO();
      appDesignDTO.appId = this.appSelected.id;
      appDesignDTO.companyId = this.informationAccount.compnanyid;
      appDesignDTO.designName = this.designNameEdit;
      appDesignDTO.isSynchronizedDelivery = false;
      appDesignDTO.appDetailDTO = new AppDetailDTO();

      this.appDesignSelected.isEdit = false;
      this.listAppDesignSelected.pop();
      this.applicationService
        .createAppDesign(appDesignDTO)
        .pipe(
          concatMap(createResult =>
            this.applicationService.findAllApplication().pipe(map(findAllResult => ({ createResult, findAllResult })))
          )
        )
        .subscribe(({ createResult, findAllResult }) => {
          this.listApp = Helper.convertResApplication(findAllResult);
          this.appSelected = this.listApp.find(e => e.id == createResult.appId);
          this.selectApplication(this.appSelected, createResult);
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
        });
    }
  }

  /**
   * cancel save application
   */
  cancelSaveAppDesign(): void {
    this.appDesignSelected.isEdit = false;
    this.isEditFormApp = false;
    if (!this.appDesignSelected.id) {
      this.listAppDesignSelected.pop();
      this.selectApplication(this.appSelected);
    }
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
  }

  /**
   * validate designName when add new/ edit
   * @param designName
   */
  validateDesignName(designName: string): boolean {
    // validate app name
    if (designName.trim().length < Constant.MIN_NO_LENGTH || designName.trim().length > Constant.MAXIMUM_TEXT_LENGTH) {
      this.designNameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.msg.validate-app-design-name')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    return true;
  }

  /**
   * delete app design
   * @returns
   */
  deleteAppDesign(): void {
    if (this.appSelected?.isEdit) {
      return;
    }
    // Check selected checkbox
    const listAppDesignChecked = this.listAppDesignSelected.filter(appDesign => appDesign.isChecked).map(appDesign => appDesign.id);
    if (listAppDesignChecked.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-app-design')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.confirm-delete-app-design'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.applicationService
          .deleteAppDesignByIds(listAppDesignChecked)
          .pipe(switchMap(() => this.getAllApplication()))
          .subscribe(() => {
            let appSelected = this.listApp.find(item => item.id == this.appSelected.id);
            if (listAppDesignChecked.includes(this.appDesignSelected.id)) {
              this.selectApplication(appSelected);
            } else {
              this.selectApplication(appSelected, this.appDesignSelected);
            }
          });
      }
    );
  }

  /**
   * Duplicate App
   */
  duplicateApp(): void {
    if (!this.appSelected) {
      return;
    }
    let listAppHaveNotBeenDelivered = this.listApp?.filter(e => !e?.appId);
    if (listAppHaveNotBeenDelivered && listAppHaveNotBeenDelivered.length > 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.please-delivery'),
          textWithHashtags: listAppHaveNotBeenDelivered.map(e => e.name)
        }
      });
      return;
    }
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    let appNew = new ApplicationDTO();
    appNew = _.cloneDeep(this.appSelected);
    appNew.id = undefined;
    appNew.appId = undefined;
    let listAppDesign = _.cloneDeep(this.listAppDesignSelected);
    if (listAppDesign && listAppDesign.length > 0) {
      listAppDesign.forEach(e => {
        e.id = undefined;
        e.appIdAPI = undefined;
        e.appId = undefined;
      });
    }
    appNew.appDesigns = listAppDesign;
    this.dialogService.showDialog(
      DialogTranslateTicketComponent,
      {
        data: {
          title: this.translateService.instant('menu-ticket-editor.edit.duplicate-application'),
          application: appNew,
          mode: Constant.MODE_EDIT
        }
      },
      async result => {
        if (!result) {
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          return;
        }
        await this.getAllApplication();
        this.listAppOutPut.emit(this.listApp);
        this.selectApplication(result);
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      }
    );
  }
  /**
   * Duplicate app design
   * @returns
   */
  duplicateAppDesign(): void {
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
    if (this.appDesignSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(DialogDuplicateApplicationDesignComponent, { data: this.appDesignSelected }, async result => {
      if (!result) {
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
        return;
      }
      this.applicationService.createAppDesign(result).subscribe(async appResp => {
        await this.getAllApplication();
        const index = this.listApp.findIndex(app => app.id == result.appId);
        this.selectApplication(this.listApp[index], appResp as AppDesignDTO);
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
      });
    });
  }

  /**
   * check or uncheck a application
   * @param id
   * @param event
   */
  changeChecked(id: number, event: Event): void {
    event.stopPropagation();
    const index = this.listAppDesignSelected.findIndex(element => element.id === id);
    this.listAppDesignSelected[index].isChecked = !this.listAppDesignSelected[index].isChecked;
  }

  /**
   * Play Preview
   */
  playPreview(): void {
    this.isPlay = !this.isPlay;
  }

  /**
   * change Display Size
   */
  changeDisplaySize(index: number) {
    this.displaySizeSelected = this.displaySize[index];
    this.isReponsive = this.displaySizeSelected.title == 'Responsive';
    this.widthReponsive = +this.displaySizeSelected.width.slice(0, -2);
    this.heightReponsive = +this.displaySizeSelected.height.slice(0, -2);
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * Delivery application
   */
  deliveryApp(): void {
    if (!this.appSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-app')
        }
      });
      return;
    }
    if (!this.appDesignSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-app-design')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogDeliveryApplicationTicketEditorComponent,
      {
        data: {
          appSelected: this.appSelected,
          appDesignSelected: this.appDesignSelected,
          informationAccount: this.informationAccount,
          languageKey: this.languageKey
        }
      },
      resultDialog => {
        if (!resultDialog) {
          return;
        }
        this.appDesignSelected.appIdAPI = resultDialog.appIdAPI;
        this.appId.emit(this.appSelected.id.toString());
        this.languageTranslationAppSelectedOutPut.emit(this.appSelected.supportedLanguageTranslation);
        this.appDesignSelected.isSynchronizedDelivery = true;
        this.appDesignSelected.appDetailDTO.imagePath = resultDialog?.appDetailDTO?.imagePath;
        this.applicationService.handlingAfterDeliveryApp(this.appSelected.id, this.appDesignSelected).subscribe(res => {
          if (!res) {
            return;
          }
          let indexApp = this.listApp.findIndex(item => item.id == resultDialog.appId);
          this.listApp[indexApp].appId = resultDialog.appIdAPI;
          let indexAppDesign = this.listApp[indexApp].appDesigns.findIndex(element => element.id == resultDialog.id);
          this.listApp[indexApp].appDesigns[indexAppDesign].appIdAPI = resultDialog.appIdAPI;
          this.listApp[indexApp].appDesigns[indexAppDesign].isSynchronizedDelivery = true;
          this.listAppOutPut.emit(this.listApp);
          this.selectApplication(this.appSelected, resultDialog);
          this.isEditFormApp = false;
          this.setDataPreview();
          this.focusOn = '';
        });
      }
    );
  }

  /**
   * Edit application detail element
   */
  editApplicationDetail(): void {
    this.shortNameOfTheApp = this.appDetailSelected['shortNameOfTheApp'];
    this.topPhoto = this.appDetailSelected['topPhoto'];
    this.mainColor = this.appDetailSelected['mainColor'];
    this.subColor = this.appDetailSelected['subColor'];
    this.backgroundColor = this.appDetailSelected['backgroundColor'];
    this.borderColor = this.appDetailSelected['borderColor'];
    this.inActiveColor = this.appDetailSelected['inActiveColor'];
    this.designNameEdit = this.appDesignSelected?.designName;
    this.isFormEditAppDetail = true;
    this.isEditFormApp = true;
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, true]);
  }

  /**
   * cancel save application detail
   */
  cancelSaveApplicationDetail(): void {
    this.isFormEditAppDetail = false;
    this.selectApplication(this.appSelected, this.appDesignSelected, true);
    this.topPhoto = _.cloneDeep(this.appDetailSelected['topPhoto']);
    this.appDetailSelected.topPhotoUrl = _.cloneDeep(this.dataTopPhotoUrlClone);
    this.setDataPreview();
    this.isEditFormApp = false;
    this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
    this.focusOn = '';
  }

  /**
   * Custom Color Hex
   * @param colorHex
   */
  customColorHex(colorHex: string): string {
    const colorCustom = colorHex ? colorHex.substring(1).toLocaleUpperCase() : '';
    return colorCustom;
  }

  /**
   * save application detail
   */
  async saveApplicationDetail(): Promise<void> {
    if (this.shortNameOfTheApp && this.shortNameOfTheApp.length > 255) {
      this.shortNameOfTheAppElementRef.nativeElement.focus();
      return;
    }
    this.isFormEditAppDetail = false;
    if (!this.fileData) {
      this.appDetailSelected['shortNameOfTheApp'] = this.shortNameOfTheApp;
      this.appDetailSelected['mainColor'] = this.mainColor == '#' ? '' : this.mainColor.toLocaleUpperCase();
      this.appDetailSelected['subColor'] = this.subColor == '#' ? '' : this.subColor.toLocaleUpperCase();
      this.appDetailSelected['backgroundColor'] = this.backgroundColor == '#' ? '' : this.backgroundColor.toLocaleUpperCase();
      this.appDetailSelected['borderColor'] = this.borderColor == '#' ? '' : this.borderColor.toLocaleUpperCase();
      this.appDetailSelected['inActiveColor'] = this.inActiveColor == '#' ? '' : this.inActiveColor.toLocaleUpperCase();
      this.appDetailSelected['topPhoto'] = this.appDetailSelected.topPhoto;
      this.appDetailSelected['topPhotoUrl'] = this.appDetailSelected.topPhotoUrl;
      this.appDetailSelected['imagePath'] = this.appDetailSelected.imagePath;
      this.appDetailSelected['idMedia'] = this.appDetailSelected.idMedia;
      this.dataTopPhotoClone = _.cloneDeep(this.appDetailSelected.topPhoto);
      this.dataTopPhotoUrlClone = _.cloneDeep(this.appDetailSelected.topPhotoUrl);
      let apppDegin = this.appDesignSelected;
      apppDegin.isSynchronizedDelivery = false;
      apppDegin.appDetailDTO = this.appDetailSelected;
      this.applicationService.createAppDesign(apppDegin).subscribe(res => {
        let indexApp = this.listApp.findIndex(item => item.id == res?.appId);
        let indexAppDesign = this.listApp[indexApp].appDesigns.findIndex(element => element.id == res?.id);
        this.listApp[indexApp].appDesigns[indexAppDesign] = res;
        this.selectApplication(this.appSelected, res);
        this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
        this.isEditFormApp = false;
        this.setDataPreview();
        this.focusOn = '';
      });
    } else {
      this.mediaService.uploadMediaFromPcToTE(this.fileData).subscribe(res => {
        if (this.appDetailSelected) {
          this.appDetailSelected.topPhotoUrl = res?.url;
          this.appDetailSelected.idMedia = res?.id;
          this.appDetailSelected.imagePath = null;
        }
        this.appDetailSelected['shortNameOfTheApp'] = this.shortNameOfTheApp;
        this.appDetailSelected['topPhoto'] = this.topPhoto;
        this.appDetailSelected['mainColor'] = this.mainColor == '#' ? '' : this.mainColor.toLocaleUpperCase();
        this.appDetailSelected['subColor'] = this.subColor == '#' ? '' : this.subColor.toLocaleUpperCase();
        this.appDetailSelected['backgroundColor'] = this.backgroundColor == '#' ? '' : this.backgroundColor.toLocaleUpperCase();
        this.appDetailSelected['borderColor'] = this.borderColor == '#' ? '' : this.borderColor.toLocaleUpperCase();
        this.appDetailSelected['inActiveColor'] = this.inActiveColor == '#' ? '' : this.inActiveColor.toLocaleUpperCase();
        this.dataTopPhotoClone = _.cloneDeep(this.appDetailSelected.topPhoto);
        this.dataTopPhotoUrlClone = _.cloneDeep(this.appDetailSelected.topPhotoUrl);
        let apppDegin = this.appDesignSelected;
        apppDegin.isSynchronizedDelivery = false;
        apppDegin.appDetailDTO = this.appDetailSelected;

        this.applicationService.createAppDesign(apppDegin).subscribe(res => {
          let indexApp = this.listApp.findIndex(item => item.id == res?.appId);
          let indexAppDesign = this.listApp[indexApp].appDesigns.findIndex(element => element.id == res?.id);
          this.listApp[indexApp].appDesigns[indexAppDesign] = res;
          this.selectApplication(this.appSelected, res);
          this.dataService.sendData([Constant.IS_EDITING_APPLICATION, false]);
          this.isEditFormApp = false;
          this.setDataPreview();
          this.focusOn = '';
        });
      });
    }
  }

  /**
   * Drop media from PC
   * @param file
   */
  public async dropMediaFromPC(e: any): Promise<void> {
    const fileInput = e.target;
    const selectedFile = fileInput.files[0];
    if (!selectedFile || !this.mapImageEnvironmentVariables) {
      return;
    }
    const mediaName = selectedFile?.name.split('.')[0];
    const mediaType = selectedFile?.name
      .split('.')
      .pop()
      .toLowerCase();
    if (!this.imageTypesAllowed.includes(mediaType)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: this.translateService.instant('index-word-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      this.fileInput.nativeElement.value = null;
      return;
    }
    if (
      !(await this.validateImage(
        selectedFile,
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_FILE_SIZE_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_WIDTH_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_HEIGHT_TAB_APPLICATION)
      ))
    ) {
      this.fileInput.nativeElement.value = null;
      return;
    }

    let fileDrop = {
      file: selectedFile,
      url: URL.createObjectURL(selectedFile),
      type: mediaType
    };
    let file = [selectedFile, fileDrop];

    const fileName = `${mediaName.split('.').shift()}_${this.IMAGE_NAME_DROP_MEDIA}.${mediaType}`;
    this.handleAfterDropMedia(mediaType, selectedFile, fileName, file);
  }

  /**
   * Handle After Drop Media
   *
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param file
   */
  private async handleAfterDropMedia(mediaType: any, fileDrop: any, fileName: any, file: any): Promise<void> {
    let fileFromPC: Media = null;
    this.topPhoto = fileDrop?.name;
    this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType);
  }

  validateImage(file: File, maxFileSize: number, maxWidth: number, maxHeight: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!file.type.startsWith('image/')) {
        resolve(false);
        return;
      }

      if (file?.size > maxFileSize * 1024 * 1024) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: Helper.formatString(
              this.translateService.instant(`ticket-editor.msg.maximum-file-size`),
              `${file?.name}`,
              `${maxFileSize}`
            )
          }
        });
        resolve(false);
        return;
      }

      const img = new Image();
      let imageLoaded = false;

      img.onload = () => {
        imageLoaded = true;
        if (img.width > maxWidth) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-width`),
                `${file?.name}`,
                `${maxWidth}`
              )
            }
          });
          resolve(false);
        } else if (img.height > maxHeight) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-height`),
                `${file?.name}`,
                `${maxHeight}`
              )
            }
          });
          resolve(false);
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        if (!imageLoaded) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.common-error')
            }
          });
          resolve(false);
        }
      };

      img.src = URL.createObjectURL(file);
    });
  }

  /**
   * Show Image After Drop
   *
   * @param fileDrop
   * @param fileName
   * @param file
   * @param fileFromPC
   * @param mediaType
   */
  private async showImgAfterDrop(fileDrop: any, fileName: any, file: any, fileFromPC: Media, mediaType: any): Promise<void> {
    this.fileData = new File([fileDrop], fileName);
    let mediaFromPC: Media = new ImageApplicaiton();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    this.appDetailSelected.topPhotoUrl = mediaFromPC.url;
    this.setDataPreview();
  }

  /**
   * onWidth
   */
  onWidth(): void {
    if (this.widthReponsive < Constant.MIN_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MIN_WIDTH_RESPONSIVE;
    } else if (this.widthReponsive > Constant.MAX_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MAX_WIDTH_RESPONSIVE;
    }
    this.displaySize[7].width = this.widthReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * onHeight
   */
  onHeight(): void {
    if (this.heightReponsive < Constant.MIN_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MIN_HEIGHT_RESPONSIVE;
    } else if (this.heightReponsive > Constant.MAX_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MAX_HEIGHT_RESPONSIVE;
    }
    this.displaySize[7].height = this.heightReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * Change Color By Input
   * @param colorType
   * @param value
   */
  changeColorByInput(colorType: string, value: string) {
    let color = `#${value}`;
    if (Constant.REGEX_COLOR_CIRCLE.test(color)) {
      this.focusOn = colorType;
      this.setDataPreview();
      this[colorType] = `#${value}`;
    }
    this.handleFocusInput(colorType);
  }

  /**
   * changeColorByPicker
   * @param prop
   * @param event
   */
  changeColorByPicker(prop: string, event: any) {
    if (prop) {
      this.focusOn = prop;
    }
  }

  /**
   * handleFocusInput
   * @param prop
   */
  handleFocusInput(prop: string): void {
    this.colorInputs.forEach(e => {
      e.isCheckInput == false;
      if (e.color == prop) {
        e.isCheckInput = true;
      }
    });
  }

  focusOut(): void {
    this.focusOn = '';
  }

  /**
   * hideColorPicker
   * @param prop
   */
  public hideColorPicker(prop: string): void {
    const color = this.colorInputs.find(e => e.color == prop);
    if (this.focusOn == prop && !color.isCheckInput) {
      this.focusOn = '';
    }
  }

  /**
   * getNameLanguageEN
   * @param language
   * @returns
   */
  getNameLanguageEN(language: any[]): string {
    if (!language) {
      return;
    }
    let listLang = Constant.LANGUAGES_SETTING.filter(e => language.includes(e.translation_language_code));
    return listLang.map(language => language.language_name_en).join(', ');
  }

  /**
   * getNameLanguageJA
   * @param language
   * @returns
   */
  getNameLanguageJA(language: any[]): string {
    if (!language) {
      return;
    }
    let listLang = Constant.LANGUAGES_SETTING.filter(e => language.includes(e.translation_language_code));
    return listLang.map(language => language.language_name_ja).join(', ');
  }
}

export interface Language {
  ja: string;
  en: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../service/dialog.service';

@Component({
  selector: 'ticket-editor-tab-crew-account',
  templateUrl: './ticket-editor-tab-crew-account.component.html',
  styleUrls: ['./ticket-editor-tab-crew-account.component.scss']
})
export class TicketEditorTabCrewAccountComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  listCrewAccount = []; // List crew account
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  accountSelected = 0; // Selected account
  isEditAccount = false; // Editing account
  listAccountSelected = []; // List account Selected
  constructor(
    private menuActionService: MenuActionService,
    private dataService: DataService,
    private ticketEditorService: TicketEditorService,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {
    // Action add
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.CREW_ACCOUNT_ENUM) {
          this.addCrewAccount(true);
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.CREW_ACCOUNT_ENUM) {
          this.editCrewAccount(true);
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.CREW_ACCOUNT_ENUM) {
          this.deleteAccount();
        }
      })
    );
  }

  ngOnInit(): void {
    this.getListCrewAccount();
  }

  /**
   * Get list crew account
   */
  getListCrewAccount(): void {
    this.listCrewAccount = this.ticketEditorService.getListCrewAccount();
  }

  /**
   * Add crew account
   * @param genElement
   */
  addCrewAccount(genElement?: boolean): void {
    if (genElement) {
      this.listCrewAccount.push({ operator: Constant.EMPTY, employeeAccountId: Constant.EMPTY, password: Constant.EMPTY, isForm: true });
      this.selectAccount(this.listCrewAccount.length - 1);
      this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, true]);
    } else {
      const data = this.listCrewAccount[this.accountSelected];
      delete data['isForm'];
      const resp = this.ticketEditorService.addCrewAccount(data);
      if (resp) {
        this.listCrewAccount.pop();
        this.getListCrewAccount();
        this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, false]);
      }
    }
  }

  /**
   * Cancel add crew account
   */
  cancelAddCrewAccount(): void {
    this.listCrewAccount.pop();
    this.accountSelected = 0;
    this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, false]);
  }

  /**
   * Select account
   * @param index
   */
  selectAccount(index: number): void {
    this.accountSelected = index;
  }

  /**
   * Add crew account
   * @param genElement
   */
  editCrewAccount(genElement?: boolean): void {
    if (genElement) {
      this.isEditAccount = true;
      this.listCrewAccount[this.accountSelected]['isForm'] = true;
      this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, true]);
    } else {
      const data = this.listCrewAccount[this.accountSelected];
      delete data['isForm'];
      const resp = this.ticketEditorService.addCrewAccount(data);
      if (resp) {
        this.listCrewAccount.pop();
        this.getListCrewAccount();
        this.isEditAccount = false;
        this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, false]);
      }
    }
  }

  /**
   * Cancel edit crew account
   */
  cancelEditCrewAccount(): void {
    this.isEditAccount = false;
    delete this.listCrewAccount[this.accountSelected]['isForm'];
    this.dataService.sendData([Constant.IS_EDITING_CREW_ACCOUNT, false]);
  }

  /**
   * Set list account selected
   * @param employeeAccountId
   */
  accountSelect(employeeAccountId: string): void {
    const index = this.listAccountSelected.indexOf(employeeAccountId);
    if (index > -1) {
      // Remove item exist in list
      this.listAccountSelected.splice(index, 1);
    } else {
      // Add item exist in list
      this.listAccountSelected.push(employeeAccountId);
    }
  }

  /**
   * Delete account
   */
  deleteAccount(): void {
    // Check selected
    if (!this.listAccountSelected.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.crew-account.choose-account')
        }
      });
      return;
    }
    this.ticketEditorService.deleteCrewAccount(this.listAccountSelected);
    this.accountSelected = 0;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, TypeMediaFileEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { Image as ImageApplicaiton } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { Ticket } from 'app/model/entity/ticket';
import { TicketDetail } from 'app/model/entity/ticket-detail';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { ApplicationService } from 'app/service/ticket-editor/application.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreviewTabTicketComponent } from './preview-tab-ticket/preview-tab-ticket.component';
@Component({
  selector: 'ticket-editor-tab-ticket',
  templateUrl: './ticket-editor-tab-ticket.component.html',
  styleUrls: ['./ticket-editor-tab-ticket.component.scss']
})
export class TicketEditorTabTicketComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  @Input() languageTranslation: string[];
  @Input() informationAccount: any;
  @Input() companies: any;
  @Input() fileGlossary: any;
  @Input() mapImageEnvironmentVariables: Map<string, number>;
  @ViewChild(PreviewTabTicketComponent) previewComponent: PreviewTabTicketComponent;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); // array subscription
  tickets: Array<Ticket> = new Array<Ticket>(); // list ticket
  ticketSelected: Ticket; // ticket Selected
  ticketSelectedClone: Ticket; // ticket Selected clone
  nameDisplayClone: string; // name display editing
  imgSrc: string = Constant.URL_IMG_DEFAULT_1; // url img default
  isEnlargePreview: boolean = false; // true if preview on
  isPlay: boolean; //true if play preview
  isEditTicketDetail: boolean = false; // returns true if editing ticket details
  isEditTicketDetailUpdate: boolean = false; // returns true if editing ticket details
  urlImage1: string;
  urlImage2: string;
  isPreview1: boolean;
  focusOn: string;
  companyId: string;
  config: any;
  titleFormData: string;
  titleEdit: string;
  titleEditSchedule: string;
  titleAdd: string;
  titleAddSchedule: string;
  titleDuplicate: string;
  image1: any;
  image2: any;
  languageSelected: string;
  time: any = '00:00';
  date: any;
  timezone: any;
  listApp: ApplicationDTO[] = []; // list Application
  /**
   * language key
   */
  public languageKey: string;
  /**
   * commonObject
   */
  commonObject: Common;
  /**
   * Constant's component
   */
  private readonly FILE_MEDIA_OBJECT = 1;
  private readonly IMAGE_NAME_DROP_MEDIA = 'imageTE';
  private readonly FILE_ATTRIBUTE = 'file';
  private readonly NAME_ATTRIBUTE = 'name';
  private readonly URL_ATTRIBUTE = 'url';

  /**
   * displaySize
   */
  displaySize = [
    { title: 'iPhoneSE', width: '375px', height: '667px' },
    { title: 'iPhone14', width: '390px', height: '844px' },
    { title: 'iPhone14 Pro Max', width: '428px', height: '926px' },
    { title: 'iPhone13 mini', width: '375px', height: '812px' },
    { title: 'Galaxy A51', width: '412px', height: '914px' },
    { title: 'Galaxy S8', width: '360px', height: '740px' },
    { title: 'Pixel 5', width: '393px', height: '851px' },
    { title: 'Responsive', width: '375px', height: '667px' }
  ];

  languages: any = new Array<any>();
  displaySizeSelected = this.displaySize[0];
  widthReponsive: number = 375; // data input width reponsive
  heightReponsive: number = 667; // data input height reponsive
  isReponsive: boolean; //true if is reponsive
  listOperators: any = new Array<any>();
  isCoupon: boolean = false;

  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPEG];

  placeholderSelectDate: string; // Placeholder for input type date
  availableDeadlineAt: Date; // Usage Start Date
  labelAddImage: string; //label Add Image
  acceptFileTypes: string = '.jpg, .png, .jpeg';
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component

  @Output()
  public colorHex = new EventEmitter<string>();
  @ViewChild('fileInput1') fileInput1: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  @ViewChild('fileInput3') fileInput3: ElementRef;
  @ViewChild('fileInput4') fileInput4: ElementRef;

  constructor(
    private translateService: TranslateService,
    private menuActionService: MenuActionService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private dataService: DataService,
    private ticketService: TicketEditorService,
    private applicationService: ApplicationService
  ) {
    this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    this.languages = Constant.LANGUAGES_SETTING.filter(e => this.languageSelected == e.translation_language_code);
    this.subscriptions.push(
      // Action add
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.addNewTicket();
        }
      }),
      // Action add schedule
      this.menuActionService.actionAddSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.addNewTicketUpdate();
        }
      }),
      // Action duplicate
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.duplicateTicket();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.editTicket();
        }
      }),
      // Action edit schedule
      this.menuActionService.actionEditSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.editTicketUpdate();
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.deleteTicket();
        }
      }),
      // Action Delete Schedule
      this.menuActionService.actionDeleteSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.deleteScheduleUpdate();
        }
      }),
      // Action Delete Schedule
      this.menuActionService.actionTranslateTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.translateTicket();
        }
      }),
      // Returns the currently displayed language
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.setDataOriginal(this.ticketSelected);
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        this.changeTitleFormData();
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey)
        };
        this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
        if (this.isEditTicketDetail && !this.ticketSelected?.ticketDetail.appId && this.titleAdd) {
          this.languages = Constant.LANGUAGES_SETTING.filter(e => this.languageSelected == e.translation_language_code);
        }
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.isPreview1 = true;
  }

  ngOnInit(): void {
    this.getListTicket();
    this.getAllApplication();
    this.ticketService.getCompanies(this.informationAccount).subscribe(data => {
      this.listOperators = data;
      this.languageKey = this.commonObject?.setting?.language;
      this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
      this.config = {
        showWeekNumbers: false,
        format: 'YYYY-MM-DD',
        firstDayOfWeek: 'mo',
        unSelectOnClick: false,
        locale: Helper.getLocale(this.languageKey)
      };
    });
    this.timezone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '')
      .replace('GMT', '');
    if (!this.timezone) {
      this.timezone = 'Z';
    }
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.applicationService.findAllApplication().subscribe(res => {
        let listAppDB = Helper.convertResApplication(res);
        if (listAppDB && listAppDB.length) {
          this.listApp = listAppDB.filter(e => e.appId);
        }
        resolve();
      });
    });
  }

  ngAfterViewInit(): void {
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  ngOnDestroy(): void {
    this.cancelTicketDetail();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * definesTheFirstLine
   */
  definesTheFirstLine(object: any): string {
    if (!object) {
      return;
    }
    if (this.languageKey == 'jp' && this.languageTranslation?.includes('ja') && Object.keys(object).includes('ja')) {
      return 'ja';
    } else if (this.languageKey == 'en' && this.languageTranslation?.includes('en') && Object.keys(object).includes('en')) {
      return 'en';
    } else {
      return Object.keys(object)[0];
    }
  }

  /**
   * getListTicket
   */
  getListTicket() {
    const idSelected = this.ticketSelected ? this.ticketSelected.ticketId : -1;
    this.ticketService.getTickets(this.informationAccount).subscribe(
      data => {
        this.tickets = Helper.convertTicket(data);
        this.setOriginalTicketName();
        if (this.tickets.length > 0) {
          if (idSelected != -1 && this.tickets.findIndex(e => e.ticketId == idSelected) != -1) {
            this.selectTicket(this.tickets[this.tickets.findIndex(e => e.ticketId == idSelected)], null);
          } else {
            this.selectTicket(this.tickets[0], null);
          }
        }
      },
      error => Helper.getErrorTicket(error, this.translateService, this.dialogService)
    );
  }

  /**
   * Set data for preview application
   *
   * @param widthReponsive
   * @param heightReponsive
   */
  setDisplaySizePreview(widthReponsive: number, heightReponsive: number): void {
    const data = {
      width: widthReponsive,
      height: heightReponsive
    };
    if (this.previewComponent !== undefined) {
      this.previewComponent.setDisplaySizePreview(data);
    }
  }

  /**
   * change Display Size
   */
  changeDisplaySize(index: number) {
    this.displaySizeSelected = this.displaySize[index];
    this.isReponsive = this.displaySizeSelected.title == 'Responsive';
    this.widthReponsive = +this.displaySizeSelected.width.slice(0, -2);
    this.heightReponsive = +this.displaySizeSelected.height.slice(0, -2);
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * onWidth
   */
  onWidth(): void {
    if (this.widthReponsive < Constant.MIN_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MIN_WIDTH_RESPONSIVE;
    } else if (this.widthReponsive > Constant.MAX_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MAX_WIDTH_RESPONSIVE;
    }
    this.displaySize[7].width = this.widthReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * onHeight
   */
  onHeight(): void {
    if (this.heightReponsive < Constant.MIN_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MIN_HEIGHT_RESPONSIVE;
    } else if (this.heightReponsive > Constant.MAX_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MAX_HEIGHT_RESPONSIVE;
    }
    this.displaySize[7].height = this.heightReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * select Ticket
   * @param ticket
   * @param event
   * @returns
   */
  selectTicket(ticket: Ticket, event: any): void {
    // check click button checkbox
    if (event?.target?.id === 'checkBoxTicket' || !ticket || !ticket.ticketId) {
      return;
    }
    this.date = undefined;
    this.time = '00:00';
    this.image1 = undefined;
    this.image2 = undefined;
    forkJoin({
      ticketDetail: this.ticketService.getTicket(this.informationAccount, +ticket.ticketId),
      ticketDetailUpdate: this.ticketService.getTicketUpdate(this.informationAccount, +ticket.ticketId)
    }).subscribe(
      data => {
        let temp = {};
        this.languages.forEach(element => {
          temp[element.translation_language_code] = '';
        });

        ticket.ticketDetail = data.ticketDetail;
        if (!ticket.ticketDetail.subtitle) {
          ticket.ticketDetail.subtitle = _.cloneDeep(temp);
        }
        if (!ticket.ticketDetail.enableOn) {
          ticket.ticketDetail.enableOn = [];
        }
        if (!ticket.ticketDetail.disableOn) {
          ticket.ticketDetail.disableOn = [];
        }
        if (!ticket.ticketDetail.enableActivateWeekdays) {
          ticket.ticketDetail.enableActivateWeekdays = [];
        }
        ticket.ticketDetail.urlImage1 = _.cloneDeep(ticket.ticketDetail.image1);
        ticket.ticketDetail.urlImage2 = _.cloneDeep(ticket.ticketDetail.image2);
        if (ticket.ticketDetail?.enableOn && ticket.ticketDetail?.enableOn.length == 1) {
          ticket.ticketDetail.enableOn = [...ticket.ticketDetail.enableOn, ...ticket.ticketDetail.enableOn];
        }
        if (ticket.ticketDetail?.disableOn && ticket.ticketDetail?.disableOn.length == 1) {
          ticket.ticketDetail.disableOn = [...ticket.ticketDetail.disableOn, ...ticket.ticketDetail.disableOn];
        }
        ticket.ticketDetailUpdate = data.ticketDetailUpdate;
        if (ticket.ticketDetailUpdate.updateScheduleAt) {
          const tmp = ticket.ticketDetailUpdate.updateScheduleAt.split('T');
          const time = tmp[1].split(':');
          this.date = tmp[0];
          this.time = `${time[0]}:${time[1]}`;
        }
        if (Object.keys(ticket.ticketDetailUpdate).length) {
          if (!ticket.ticketDetailUpdate.subtitle) {
            ticket.ticketDetailUpdate.subtitle = _.cloneDeep(temp);
          }
          ticket.ticketDetailUpdate.urlImage1 = _.cloneDeep(ticket.ticketDetailUpdate.image1);
          ticket.ticketDetailUpdate.urlImage2 = _.cloneDeep(ticket.ticketDetailUpdate.image2);
          if (!ticket.ticketDetailUpdate.enableOn) {
            ticket.ticketDetailUpdate.enableOn = [];
          }
          if (!ticket.ticketDetailUpdate.disableOn) {
            ticket.ticketDetailUpdate.disableOn = [];
          }
          if (!ticket.ticketDetailUpdate.enableActivateWeekdays) {
            ticket.ticketDetailUpdate.enableActivateWeekdays = [];
          }
          if (ticket.ticketDetailUpdate?.enableOn && ticket.ticketDetailUpdate?.enableOn.length == 1) {
            ticket.ticketDetailUpdate.enableOn = [...ticket.ticketDetailUpdate.enableOn, ...ticket.ticketDetailUpdate.enableOn];
          }
          if (ticket.ticketDetailUpdate?.disableOn && ticket.ticketDetailUpdate?.disableOn.length == 1) {
            ticket.ticketDetailUpdate.disableOn = [...ticket.ticketDetailUpdate.disableOn, ...ticket.ticketDetailUpdate.disableOn];
          }
        }
        this.setDataOriginal(ticket);
        this.ticketSelected = ticket;
        this.isCoupon = this.ticketSelected.ticketType == 'coupon' ? true : false;
        const languagesCodes = [];
        Object.keys(this.ticketSelected.ticketName).forEach(e => {
          languagesCodes.push(e);
        });
        this.languages = Constant.LANGUAGES_SETTING.filter(e => languagesCodes.includes(e.translation_language_code));
        this.ticketSelectedClone = _.cloneDeep(this.ticketSelected);
        if (this.ticketSelected.ticketDetailUpdate?.updateScheduleAt) {
          this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, true]);
        } else {
          this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
        }
      },
      error => Helper.getErrorTicket(error, this.translateService, this.dialogService)
    );
  }

  /**
   * setDataOriginal
   * @param ticket
   */
  setDataOriginal(ticket: Ticket): void {
    ticket.ticketDetail.ticketName.original = ticket.ticketDetail.ticketName[`${this.definesTheFirstLine(ticket.ticketDetail.ticketName)}`];
    ticket.ticketDetail.companyName.original =
      ticket.ticketDetail.companyName[`${this.definesTheFirstLine(ticket.ticketDetail.companyName)}`];
    ticket.ticketDetail.operatorName.original =
      ticket.ticketDetail.operatorName[`${this.definesTheFirstLine(ticket.ticketDetail.operatorName)}`];
    if (!ticket.ticketDetail.enableOn || !ticket.ticketDetail.enableOn.length) {
      ticket.ticketDetail.enableOn = [];
    }
    if (!ticket.ticketDetail.disableOn || !ticket.ticketDetail.disableOn.length) {
      ticket.ticketDetail.disableOn = [];
    }
    if (!ticket.ticketDetailUpdate.enableOn || !ticket.ticketDetailUpdate.enableOn.length) {
      ticket.ticketDetailUpdate.enableOn = [];
    }
    if (!ticket.ticketDetailUpdate.disableOn || !ticket.ticketDetailUpdate.disableOn.length) {
      ticket.ticketDetailUpdate.disableOn = [];
    }
  }

  /**
   * setOriginalTicketName
   */
  setOriginalTicketName(): void {
    this.tickets.forEach(ticket => {
      ticket.ticketName.original = ticket.ticketName[`${this.definesTheFirstLine(ticket.ticketName)}`];
    });
  }

  /**
   * cancel Ticket Detail
   */
  cancelTicketDetail(): void {
    if (!this.tickets.length) {
      this.ticketSelected = undefined;
    } else {
      if (this.ticketSelected?.isChooseDetailUpdate) {
        this.isEditTicketDetailUpdate = false;
        this.ticketSelected.isChooseDetailUpdate = false;
      } else {
        this.isEditTicketDetail = false;
      }
      const index = this.tickets.findIndex(e => e.ticketId == this.ticketSelected.ticketId);
      this.selectTicket(index != -1 ? this.tickets[index] : this.tickets[0], null);
    }
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
    this.focusOn = '';
    this.titleFormData = '';
    delete this.titleEdit;
    delete this.titleEditSchedule;
    delete this.titleAdd;
    delete this.titleAddSchedule;
    delete this.titleDuplicate;
  }

  /**
   * save TicketDetail
   */
  saveTicketDetail(): void {
    this.focusOn = '';
    if (this.validateBeforeSave()) {
      return;
    }
    this.ticketSelectedClone = _.cloneDeep(this.ticketSelected);
    const detail = this.ticketSelected.isChooseDetailUpdate ? this.ticketSelected.ticketDetailUpdate : this.ticketSelected.ticketDetail;
    delete detail.ticketName.original;
    let payload = {
      ticketType: detail.ticketType,
      ticketName: detail.ticketName,
      ticketNameShort: detail.ticketNameShort,
      operatorId: +detail.operatorId,
      appId: detail.appId,
      subtitle: detail.subtitle,
      note: detail.note,
      activateMessage: detail.activateMessage,
      priceAdult: detail.priceAdult,
      priceCustom1: detail.priceCustom1,
      inUseOperation: +detail.inUseOperation ?? undefined,
      usingPeriod: detail.inUseOperation != 2 ? +detail.usingPeriod ?? undefined : undefined,
      usingPeriodType: detail.inUseOperation != 2 ? detail.usingPeriodType ?? null : undefined,
      enableActivateWeekdays: detail.enableActivateWeekdays ?? undefined,
      availableActiveStartTime: detail.availableActiveStartTime ? detail.availableActiveStartTime : null,
      availableActiveEndTime: detail.availableActiveEndTime ? detail.availableActiveEndTime : null,
      availableShowEndTime: detail.availableShowEndTime ? detail.availableShowEndTime : null,
      enableOn: detail.enableOn ?? null,
      disableOn: detail.disableOn ?? null,
      colorCircleUpper: detail.colorCircleUpper?.toLocaleUpperCase(),
      colorCircleLower: detail.colorCircleLower?.toLocaleUpperCase(),
      colorCircleRotation: detail.colorCircleRotation?.toLocaleUpperCase(),
      image1Path: detail.image1Path ?? null,
      image2Path: detail.image2Path ?? null,
      colorFontTicket1Top: detail.colorFontTicket1Top?.toLocaleUpperCase(),
      colorFontTicket1Center: detail.colorFontTicket1Center?.toLocaleUpperCase(),
      colorFontTicket1Bottom: detail.colorFontTicket1Bottom?.toLocaleUpperCase(),
      colorFontTicket2Top: detail.colorFontTicket2Top?.toLocaleUpperCase(),
      colorFontTicket2Center: detail.colorFontTicket2Center?.toLocaleUpperCase(),
      colorFontTicket2Bottom: detail.colorFontTicket2Bottom?.toLocaleUpperCase()
    };
    if (payload.ticketType == 'coupon') {
      payload[`couponCount`] = +detail.couponCount;
      payload[`couponGrantingType`] = detail.couponGrantingType ?? null;
      this.image1 = undefined;
      this.image2 = undefined;
      payload.colorCircleUpper = null;
      payload.colorCircleLower = null;
      payload.colorCircleRotation = null;
      payload.colorFontTicket1Top = null;
      payload.colorFontTicket1Center = null;
      payload.colorFontTicket1Bottom = null;
      payload.colorFontTicket2Top = null;
      payload.colorFontTicket2Center = null;
      payload.colorFontTicket2Bottom = null;
    }
    if (
      this.titleFormData.includes(this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule')) ||
      this.titleFormData.includes(this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule'))
    ) {
      delete payload.operatorId;
      delete payload.priceAdult;
      delete payload.priceCustom1;
      delete payload.inUseOperation;
      delete payload.appId;
      delete payload.enableOn;
      delete payload.disableOn;
      delete payload.ticketType;
    }
    if (this.image1 || this.image2) {
      let payload1 = {
        image: this.image1,
        uploadImageType: this.ticketSelected.isChooseDetailUpdate ? 'ticket_updates' : 'tickets'
      };
      let payload2 = {
        image: this.image2,
        uploadImageType: this.ticketSelected.isChooseDetailUpdate ? 'ticket_updates' : 'tickets'
      };
      const image1$ = this.image1 ? this.ticketService.postImage(this.informationAccount, payload1) : of({});
      const image2$ = this.image2 ? this.ticketService.postImage(this.informationAccount, payload2) : of({});
      forkJoin([image1$, image2$])
        .pipe(
          map(([result1, result2]) => {
            return { result1, result2 };
          })
        )
        .subscribe(
          result => {
            payload.image1Path = result?.result1?.filePath ? result?.result1?.filePath : detail.image1Path ?? null;
            payload.image2Path = result?.result2?.filePath ? result?.result2?.filePath : detail.image2Path ?? null;
            this.callAPISaveTicket(payload);
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
          }
        );
    } else {
      this.callAPISaveTicket(payload);
    }
  }

  /**
   * callAPISaveTicket
   * @param payload
   */
  callAPISaveTicket(payload) {
    switch (this.titleFormData) {
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add'):
        //call api add
        if (!Object.keys(payload.subtitle).length) {
          payload.subtitle = null;
        } else {
          if (!Object.values(payload.subtitle).some(e => e != '')) {
            payload.subtitle = null;
          } else {
            this.languages.forEach(e => {
              if (!payload.subtitle.hasOwnProperty(e.translation_language_code)) {
                payload.subtitle[e.translation_language_code] = '';
              }
            });
          }
        }
        this.ticketService.postTicket(this.informationAccount, payload).subscribe(
          data => {
            this.ticketSelected.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTickets(this.informationAccount).subscribe(
              listTicket => {
                this.tickets = Helper.convertTicket(listTicket);
                this.setOriginalTicketName();
                const index = this.tickets.findIndex(e => e.ticketId == data.ticketId);
                this.selectTicket(this.tickets[index], null);
                this.titleFormData = '';
                delete this.titleAdd;
              },
              error => {
                Helper.getErrorTicket(error, this.translateService, this.dialogService);
                this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
                return;
              }
            );
            this.image1 = undefined;
            this.image2 = undefined;
            this.setDataOriginal(this.ticketSelected);
          },
          error => {
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            return;
          }
        );
        this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate'):
        //call api add
        if (!Object.keys(payload.subtitle).length) {
          payload.subtitle = null;
        } else {
          if (!Object.values(payload.subtitle).some(e => e != '')) {
            payload.subtitle = null;
          } else {
            this.languages.forEach(e => {
              if (!payload.subtitle.hasOwnProperty(e.translation_language_code)) {
                payload.subtitle[e.translation_language_code] = '';
              }
            });
          }
        }
        this.ticketService.postTicket(this.informationAccount, payload).subscribe(
          data => {
            this.ticketSelected.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTickets(this.informationAccount).subscribe(
              listTicket => {
                this.tickets = Helper.convertTicket(listTicket);
                this.setOriginalTicketName();
                const index = this.tickets.findIndex(e => e.ticketId == data.ticketId);
                this.selectTicket(this.tickets[index], null);
                this.titleFormData = '';
                delete this.titleDuplicate;
              },
              error => Helper.getErrorTicket(error, this.translateService, this.dialogService)
            );
            this.image1 = undefined;
            this.image2 = undefined;
            this.setDataOriginal(this.ticketSelected);
          },
          error => {
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
          }
        );
        this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule'):
        if (!Object.keys(payload.subtitle).length) {
          payload.subtitle = null;
        } else {
          if (!Object.values(payload.subtitle).some(e => e != '')) {
            payload.subtitle = null;
          } else {
            this.languages.forEach(e => {
              if (!payload.subtitle.hasOwnProperty(e.translation_language_code)) {
                payload.subtitle[e.translation_language_code] = '';
              }
            });
          }
        }
        payload[`ticketId`] = +this.ticketSelected.ticketId;
        payload.enableActivateWeekdays = payload.enableActivateWeekdays.length ? payload.enableActivateWeekdays : null;
        const dateSchedule = new Date(this.date);
        const dateInfo = {
          year: dateSchedule.getFullYear(),
          month: dateSchedule.getMonth(),
          day: dateSchedule.getDate(),
          hour: parseInt(this.time.split(':')[0]),
          minute: parseInt(this.time.split(':')[1]),
          second: 0
        };
        const momentObject = moment(dateInfo).format('YYYY-MM-DDTHH:mm:ss');
        this.ticketSelected.ticketDetailUpdate.updateScheduleAt = momentObject + this.timezone;
        payload[`updateScheduleAt`] = momentObject + this.timezone;
        this.ticketService.postTicketUpdate(this.informationAccount, payload).subscribe(
          data => {
            this.ticketSelected.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTicketUpdate(this.informationAccount, data.ticketId).subscribe(ticketDetailUpdate => {
              if (ticketDetailUpdate?.enableOn && ticketDetailUpdate?.enableOn.length == 1) {
                ticketDetailUpdate.enableOn = [...ticketDetailUpdate.enableOn, ...ticketDetailUpdate.enableOn];
              }
              if (ticketDetailUpdate?.disableOn && ticketDetailUpdate?.disableOn.length == 1) {
                ticketDetailUpdate.disableOn = [...ticketDetailUpdate.disableOn, ...ticketDetailUpdate.disableOn];
              }
              this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
              this.ticketSelected.ticketDetailUpdate.urlImage1 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image1);
              this.ticketSelected.ticketDetailUpdate.urlImage2 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image2);
              this.setDataOriginal(this.ticketSelected);
              this.titleFormData = '';
              delete this.titleAddSchedule;
            });
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            return;
          }
        );
        this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, true]);
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit'):
        //call api edit
        const payloadEdit = {
          ticketId: +this.ticketSelected.ticketId ?? null,
          ticketName: payload.ticketName ?? null,
          ticketNameShort: payload.ticketNameShort ?? null,
          subtitle: Object.keys(payload.subtitle).length ? payload.subtitle : null,
          note: payload.note ?? null,
          activateMessage: payload.activateMessage ?? null,
          enableOn: payload.enableOn ?? null,
          disableOn: payload.disableOn ?? null
        };
        if (!Object.keys(payload.subtitle).length) {
          payload.subtitle = null;
        } else {
          if (!Object.values(payload.subtitle).some(e => e != '')) {
            payload.subtitle = null;
          } else {
            this.languages.forEach(e => {
              if (!payload.subtitle.hasOwnProperty(e.translation_language_code)) {
                payload.subtitle[e.translation_language_code] = '';
              }
            });
          }
        }
        this.ticketService.putTicket(this.informationAccount, payloadEdit).subscribe(
          data => {
            this.ticketSelected.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTicket(this.informationAccount, data.ticketId).subscribe(ticketDetail => {
              if (ticketDetail?.enableOn && ticketDetail?.enableOn.length == 1) {
                ticketDetail.enableOn = [...ticketDetail.enableOn, ...ticketDetail.enableOn];
              }
              if (ticketDetail?.disableOn && ticketDetail?.disableOn.length == 1) {
                ticketDetail.disableOn = [...ticketDetail.disableOn, ...ticketDetail.disableOn];
              }
              this.ticketSelected.ticketDetail = ticketDetail;
              this.ticketSelected.ticketDetail.urlImage1 = _.cloneDeep(this.ticketSelected.ticketDetail.image1);
              this.ticketSelected.ticketDetail.urlImage2 = _.cloneDeep(this.ticketSelected.ticketDetail.image2);
              this.setDataOriginal(this.ticketSelected);
              this.titleFormData = '';
              delete this.titleEdit;
            });
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            return;
          }
        );
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule'):
        //call api add/edit-schedule
        payload[`ticketId`] = +this.ticketSelected.ticketId;
        payload.enableActivateWeekdays = payload.enableActivateWeekdays.length ? payload.enableActivateWeekdays : null;
        const selectedTime = new Date(this.date);
        const dateInfoEditor = {
          year: selectedTime.getFullYear(),
          month: selectedTime.getMonth(),
          day: selectedTime.getDate(),
          hour: parseInt(this.time.split(':')[0]),
          minute: parseInt(this.time.split(':')[1]),
          second: 0
        };
        const momentObjectEditor = moment(dateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
        this.ticketSelected.ticketDetailUpdate.updateScheduleAt = momentObjectEditor + this.timezone;
        payload[`updateScheduleAt`] = momentObjectEditor + this.timezone;
        if (!Object.keys(payload.subtitle).length) {
          payload.subtitle = null;
        } else {
          if (!Object.values(payload.subtitle).some(e => e != '')) {
            payload.subtitle = null;
          } else {
            this.languages.forEach(e => {
              if (!payload.subtitle.hasOwnProperty(e.translation_language_code)) {
                payload.subtitle[e.translation_language_code] = '';
              }
            });
          }
        }
        this.ticketService.postTicketUpdate(this.informationAccount, payload).subscribe(
          data => {
            this.ticketSelected.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTicketUpdate(this.informationAccount, data.ticketId).subscribe(ticketDetailUpdate => {
              if (ticketDetailUpdate?.enableOn && ticketDetailUpdate?.enableOn.length == 1) {
                ticketDetailUpdate.enableOn = [...ticketDetailUpdate.enableOn, ...ticketDetailUpdate.enableOn];
              }
              if (ticketDetailUpdate?.disableOn && ticketDetailUpdate?.disableOn.length == 1) {
                ticketDetailUpdate.disableOn = [...ticketDetailUpdate.disableOn, ...ticketDetailUpdate.disableOn];
              }
              this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
              this.ticketSelected.ticketDetailUpdate.urlImage1 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image1);
              this.ticketSelected.ticketDetailUpdate.urlImage2 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image2);
              this.setDataOriginal(this.ticketSelected);
              this.titleFormData = '';
              delete this.titleEditSchedule;
            });
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            return;
          }
        );
        break;
    }
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
  }

  /**
   * validateBeforeSave
   * @returns
   */
  validateBeforeSave(): boolean {
    const type = this.ticketSelected.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    let flag = false;
    if (
      (this.ticketSelected.isChooseDetailUpdate &&
        this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule')) ||
      (!this.ticketSelected.isChooseDetailUpdate &&
        (this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add') ||
          this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate')))
    ) {
      if (!this.ticketSelected[type].operatorId) {
        this.ticketSelected[type]['isValidateOperatorNameOriginal'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateOperatorNameOriginal'] = false;
      }
      if (!this.ticketSelected[type].appId && (this.titleAdd || this.titleDuplicate)) {
        this.ticketSelected[type]['isValidateAppName'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAppName'] = false;
      }
      if (!this.ticketSelected[type].ticketType?.trim().length) {
        this.ticketSelected[type]['isValidateTicketType'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateTicketType'] = false;
      }
      if (this.ticketSelected[type].ticketType == 'coupon') {
        if (!this.ticketSelected[type].couponCount) {
          this.ticketSelected[type]['isValidateCouponCount'] = true;
          flag = true;
        } else if (!this.validateHalfsizeNumber(this.ticketSelected[type].couponCount, 1, 9999)) {
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateCouponCount'] = false;
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
        }
      } else if (this.ticketSelected[type].couponCount && !this.validateHalfsizeNumber(this.ticketSelected[type].couponCount, 1, 9999)) {
        this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
      }
      if (
        this.ticketSelected[type].usingPeriod &&
        this.ticketSelected[type].usingPeriodType == 'D' &&
        !this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 7)
      ) {
        this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
        this.ticketSelected[type]['isValidateUsingPeriod-D'] = true;
        flag = true;
      } else if (
        this.ticketSelected[type].usingPeriod &&
        this.ticketSelected[type].usingPeriodType == 'm' &&
        !this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 5999)
      ) {
        this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
        this.ticketSelected[type]['isValidateUsingPeriod-m'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
        this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
      }
      if (
        this.ticketSelected[type].availableActiveStartTime &&
        !this.validateTimeFormat(this.ticketSelected[type].availableActiveStartTime)
      ) {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
      }
      if (this.ticketSelected[type].availableActiveEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableActiveEndTime)) {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
      }
      if (this.ticketSelected[type].availableShowEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableShowEndTime)) {
        this.ticketSelected[type]['isValidateAvailableShowEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
      }

      this.languages.forEach(e => {
        if (!this.ticketSelected[type].ticketName[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = true;
          flag = true;
        } else {
          if (!this.validateFullsize(this.ticketSelected[type].ticketName[e.translation_language_code], 1, 100)) {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = false;
          }
          this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = false;
        }
        if (!this.ticketSelected[type].ticketNameShort[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}`] = true;
          flag = true;
        } else {
          if (!this.validateFullsize(this.ticketSelected[type].ticketNameShort[e.translation_language_code], 1, 50)) {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = false;
          }
          this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}`] = false;
        }
        if (this.ticketSelected[type].subtitle[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateSubtitleOriginalHalfsize`] = this.validateFullsize(
            this.ticketSelected[type].subtitle[e.translation_language_code],
            1,
            100
          )
            ? false
            : true;
          if (!flag) {
            flag = !this.validateFullsize(this.ticketSelected[type].subtitle[e.translation_language_code], 1, 100);
          }
        }
        if (!this.ticketSelected[type].note[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}`] = true;
          flag = true;
        } else if (!this.validateFullsize(this.ticketSelected[type].note[e.translation_language_code], 1, 1000)) {
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = true;
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}`] = false;
          flag = true;
        } else {
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = false;
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}`] = false;
        }

        if (!this.ticketSelected[type].activateMessage[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = true;
          flag = true;
        } else if (!this.validateFullsize(this.ticketSelected[type].activateMessage[e.translation_language_code], 1, 1000)) {
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = true;
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
          flag = true;
        } else {
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = false;
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
        }
      });
      if (this.ticketSelected.isChooseDetailUpdate) {
        if (!this.date || !this.date.trim().length) {
          this.ticketSelected[type]['isValidateUpdateScheduleAt'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateUpdateScheduleAt'] = false;
        }
      }
    } else {
      if (
        this.ticketSelected[type].usingPeriod &&
        this.ticketSelected[type].usingPeriodType == 'D' &&
        !this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 7)
      ) {
        this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
        this.ticketSelected[type]['isValidateUsingPeriod-D'] = true;
        flag = true;
      } else if (
        this.ticketSelected[type].usingPeriod &&
        this.ticketSelected[type].usingPeriodType == 'm' &&
        !this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 5999)
      ) {
        this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
        this.ticketSelected[type]['isValidateUsingPeriod-m'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
        this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
      }
      if (
        this.ticketSelected[type].availableActiveStartTime &&
        !this.validateTimeFormat(this.ticketSelected[type].availableActiveStartTime)
      ) {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
      }
      if (this.ticketSelected[type].availableActiveEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableActiveEndTime)) {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
      }
      if (this.ticketSelected[type].availableShowEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableShowEndTime)) {
        this.ticketSelected[type]['isValidateAvailableShowEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
      }
      this.languages.forEach(e => {
        if (this.ticketSelected[type].inUseOperation != 2) {
          if (!this.ticketSelected[type].ticketName[e.translation_language_code]?.trim().length) {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = true;
            flag = true;
          } else {
            if (!this.validateFullsize(this.ticketSelected[type].ticketName[e.translation_language_code], 1, 100)) {
              this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = true;
              flag = true;
            } else {
              this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = false;
            }
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = false;
          }
        }
        if (!this.ticketSelected[type].ticketNameShort[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}`] = true;
          flag = true;
        } else {
          if (!this.validateFullsize(this.ticketSelected[type].ticketNameShort[e.translation_language_code], 1, 50)) {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = false;
          }
          this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}`] = false;
        }
        if (this.ticketSelected[type].subtitle[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateSubtitleOriginalHalfsize`] = this.validateFullsize(
            this.ticketSelected[type].subtitle[e.translation_language_code],
            1,
            100
          )
            ? false
            : true;
          if (!flag) {
            flag = !this.validateFullsize(this.ticketSelected[type].subtitle[e.translation_language_code], 1, 100);
          }
        }
        if (!this.ticketSelected[type].note[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}`] = true;
          flag = true;
        } else if (!this.validateFullsize(this.ticketSelected[type].note[e.translation_language_code], 1, 1000)) {
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = true;
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}`] = false;
          flag = true;
        } else {
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = false;
          this.ticketSelected[type][`isValidateNote${e.translation_language_code}`] = false;
        }

        if (!this.ticketSelected[type].activateMessage[e.translation_language_code]?.trim().length) {
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = true;
          flag = true;
        } else if (!this.validateFullsize(this.ticketSelected[type].activateMessage[e.translation_language_code], 1, 1000)) {
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = true;
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
          flag = true;
        } else {
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = false;
          this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
        }
      });
    }

    if (!this.ticketSelected[type]?.enableOn?.[0] && this.ticketSelected[type]?.enableOn?.[1]) {
      this.ticketSelected[type]['isValidateEnableOn0'] = true;
      flag = true;
    } else if (this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1]) {
      this.ticketSelected[type]['isValidateEnableOn1'] = true;
    } else if (this.ticketSelected[type]?.enableOn?.[0] && this.ticketSelected[type]?.enableOn?.[1]) {
      const dateEnableOnStart = new Date(this.ticketSelected[type]?.enableOn?.[0]);
      const dateEnableOnEnd = new Date(this.ticketSelected[type]?.enableOn?.[1]);
      if (dateEnableOnStart > dateEnableOnEnd) {
        this.ticketSelected[type]['isValidateEnableOnValid'] = true;
        flag = true;
      }
    }

    if (!this.ticketSelected[type]?.disableOn?.[0] && this.ticketSelected[type]?.disableOn?.[1]) {
      this.ticketSelected[type]['isValidateDisableOn0'] = true;
      flag = true;
    } else if (this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1]) {
      this.ticketSelected[type]['isValidateDisableOn1'] = true;
      flag = true;
    } else if (this.ticketSelected[type]?.disableOn?.[0] && this.ticketSelected[type]?.disableOn?.[1]) {
      const dateDisableOnStart = new Date(this.ticketSelected[type]?.disableOn?.[0]);
      const dateDisableOnEnd = new Date(this.ticketSelected[type]?.disableOn?.[1]);
      if (dateDisableOnStart > dateDisableOnEnd) {
        this.ticketSelected[type]['isValidateDisableOnValid'] = true;
        flag = true;
      }
    }
    return flag;
  }

  /**
   * check or uncheck a application
   * @param id
   * @param event
   */
  async changeChecked(ticketId: number, event: any): Promise<void> {
    event.stopPropagation();
    const index = this.tickets.findIndex(ticket => ticket.ticketId === ticketId);
    this.tickets[index].isChecked = !this.tickets[index].isChecked;
    if (this.tickets[index].ticketId == this.ticketSelected?.ticketId) {
      this.ticketSelected.isChecked = this.tickets[index].isChecked;
    }
    const listTicketIsChecked = this.tickets.filter(e => e.isChecked);
    let checkPlanningToUpdate = true;
    if (!listTicketIsChecked || listTicketIsChecked.length == 0) {
      checkPlanningToUpdate = false;
    } else {
      for (let i = 0; i < listTicketIsChecked.length; i++) {
        const element = listTicketIsChecked[i];
        await this.ticketService
          .getTicketUpdate(this.informationAccount, +element.ticketId)
          .toPromise()
          .then(res => {
            if (!res || Helper.isObjectEmpty(res)) {
              checkPlanningToUpdate = false;
            }
          })
          .catch(error => {
            checkPlanningToUpdate = false;
          });

        if (!checkPlanningToUpdate) {
          break;
        }
      }
    }
    this.dataService.sendData([Constant.THERE_ARE_PLANS_FOR_UPDATES, checkPlanningToUpdate]);
  }

  /**
   * change Checked Day for ticket detail
   * @param id
   * @param e
   */
  changeCheckedDay(key: string, e): void {
    e.stopPropagation();
    const type = this.ticketSelected.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    if (this.ticketSelected[`${type}`].enableActivateWeekdays?.includes(key)) {
      const index = this.ticketSelected[`${type}`].enableActivateWeekdays.indexOf(key);
      this.ticketSelected[`${type}`].enableActivateWeekdays.splice(index, 1);
    } else {
      this.ticketSelected[`${type}`].enableActivateWeekdays.push(key);
    }
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * change State Preview
   */
  changeStatePreview(): void {
    this.isPlay = !this.isPlay;
  }

  /**
   * add New Ticket
   */
  addNewTicket(): void {
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add');
    this.ticketSelected = new Ticket();
    const ticketDetail = new TicketDetail();
    ticketDetail.usingPeriodType = 'D';
    ticketDetail.colorCircleUpper = '#FFFFFFFF';
    ticketDetail.colorCircleLower = '#FFFFFFFF';
    ticketDetail.colorCircleRotation = '#000000FF';
    ticketDetail.colorFontTicket1Top = '#FFFFFF';
    ticketDetail.colorFontTicket1Center = '#FFFFFF';
    ticketDetail.colorFontTicket1Bottom = '#FFFFFF';
    ticketDetail.colorFontTicket2Top = '#FFFFFF';
    ticketDetail.colorFontTicket2Center = '#FFFFFF';
    ticketDetail.colorFontTicket2Bottom = '#FFFFFF';
    ticketDetail.activateMessage.en = 'Would you like to use your ticket? Tickets will no longer be refundable.';
    ticketDetail.activateMessage.ja = 'チケットを使い始めてよろしいですか？使用開始後は払い戻しできなくなります。';
    const ticketDetailUpdate = new TicketDetail();
    ticketDetailUpdate.usingPeriodType = 'D';
    ticketDetailUpdate.colorCircleUpper = '#FFFFFFFF';
    ticketDetailUpdate.colorCircleLower = '#FFFFFFFF';
    ticketDetailUpdate.colorCircleRotation = '#000000FF';
    ticketDetailUpdate.colorFontTicket1Top = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket1Center = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket1Bottom = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket2Top = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket2Center = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket2Bottom = '#FFFFFF';
    ticketDetail.totalCount = ticketDetail.adultCount + ticketDetail.customCount1 + ticketDetail.customCount2 + ticketDetail.customCount3;
    ticketDetail.totalCount ? ticketDetail.totalCount : (ticketDetail.totalCount = 0);
    this.ticketSelected.ticketDetail = ticketDetail;
    ticketDetailUpdate.totalCount =
      ticketDetailUpdate.adultCount + ticketDetailUpdate.customCount1 + ticketDetailUpdate.customCount2 + ticketDetailUpdate.customCount3;
    ticketDetailUpdate.totalCount ? ticketDetailUpdate.totalCount : (ticketDetailUpdate.totalCount = 0);
    this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
    this.titleAdd = this.titleFormData;
    this.isEditTicketDetail = true;
    this.isEditTicketDetailUpdate = false;
    this.ticketSelected.isChooseDetailUpdate = false;
    this.languages = Constant.LANGUAGES_SETTING.filter(e => this.languageSelected == e.translation_language_code);
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail();
  }

  /**
   * add New Ticket Update
   */
  addNewTicketUpdate(): void {
    if (!this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
    const ticketDetailUpdate = _.cloneDeep(this.ticketSelected.ticketDetail);
    ticketDetailUpdate.subtitle = this.ticketSelected.ticketDetail.subtitle ?? {};
    this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
    this.titleAddSchedule = this.titleFormData;
    this.isEditTicketDetailUpdate = true;
    this.isEditTicketDetail = false;
    this.ticketSelected.isChooseDetailUpdate = true;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail();
  }

  /**
   * duplicateTicket
   */
  duplicateTicket(): void {
    if (!this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate');
    this.ticketSelected = _.cloneDeep(this.ticketSelected);
    this.ticketSelected.ticketId = undefined;
    const index = this.listApp.findIndex(e => e.appId == this.ticketSelected?.ticketDetail.appId);
    if (index == -1) {
      this.ticketSelected.appId = null;
      this.ticketSelected.ticketDetail.appId = null;
    }
    this.ticketSelected.ticketId = undefined;
    this.titleDuplicate = this.titleFormData;
    this.isEditTicketDetail = true;
    this.isEditTicketDetailUpdate = false;
    this.ticketSelected.isChooseDetailUpdate = false;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail();
  }

  /**
   * edit Ticket
   */
  editTicket(): void {
    if (!this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit');
    this.titleEdit = this.titleFormData;
    this.isEditTicketDetail = true;
    this.isEditTicketDetailUpdate = false;
    this.ticketSelected.isChooseDetailUpdate = false;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail();
  }

  /**
   * edit Schedule Update
   */
  editTicketUpdate(): void {
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
    this.titleEditSchedule = this.titleFormData;
    this.isEditTicketDetailUpdate = true;
    this.isEditTicketDetail = false;
    this.ticketSelected.isChooseDetailUpdate = true;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail();
  }

  /**
   * delete Ticket
   */
  deleteTicket(): void {
    const idsDelete = this.tickets?.filter(e => e.isChecked).map(item => item.ticketId);
    if (!idsDelete?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.ticket-tab.confirm-delete'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        // Delete application
        if (!idsDelete || !idsDelete.length) {
          return;
        }
        let payloads = [];
        for (let ticketId of idsDelete) {
          let payload = {
            ticketId: ticketId
          };
          payloads.push(payload);
        }
        this.ticketService.deleteTicketsSequentially(this.informationAccount, payloads).subscribe(
          data => {
            this.getListTicket();
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
          }
        );
      }
    );
  }

  /**
   * delete Schedule
   */
  deleteScheduleUpdate(): void {
    let idsDelete = this.tickets?.filter(e => e.isChecked);
    if (!idsDelete?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.ticket-tab.confirm-delete-update'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no'),
          title: this.translateService.instant('ticket-editor.tilte-delete-update')
        }
      },
      result => {
        if (!result) {
          return;
        }

        // Delete application
        if (!idsDelete || !idsDelete.length) {
          return;
        }
        let payloads = [];
        for (let ticket of idsDelete) {
          let payload = {
            ticketId: ticket.ticketId
          };
          payloads.push(payload);
        }
        this.ticketService.deleteTicketUpdatesSequentially(this.informationAccount, payloads).subscribe(
          data => {
            this.getListTicket();
            this.dataService.sendData([Constant.THERE_ARE_PLANS_FOR_UPDATES, false]);
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
          }
        );
      }
    );
    this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
  }

  /**
   * openDetail
   */
  openDetail() {
    this.addDetail('TicketName');
    this.addDetail('NameShort');
    this.addDetail('Subtitle');
    this.addDetail('Note');
    this.addDetail('ActivateMessage');
  }

  /**
   * hide color picker
   */
  public hideColorPicker($event, prop: string): void {
    if (this.focusOn == prop && !this.ticketSelected.ticketDetail[`${prop}-input`]) {
      this.focusOn = '';
    }
  }

  /**
   * Custom Color Hex
   * @param colorHex
   */
  customColorHex(colorHex: string): string {
    const colorCustom = colorHex ? colorHex.substring(1).toLocaleUpperCase() : '';
    return colorCustom;
  }

  /**
   * Change Color By Input
   * @param prop
   * @param event
   */
  changeColorByInput(prop: string, event: any) {
    let color = `#${event.target.value}`;
    if (
      Constant.REGEX_COLOR.test(color) &&
      [
        'colorFontTicket2Bottom',
        'colorFontTicket2Center',
        'colorFontTicket2Top',
        'colorFontTicket1Bottom',
        'colorFontTicket1Center',
        'colorFontTicket1Top'
      ].includes(prop)
    ) {
      this.ticketSelected.isChooseDetailUpdate
        ? (this.ticketSelected.ticketDetailUpdate[prop] = color)
        : (this.ticketSelected.ticketDetail[prop] = color);
    } else if (
      Constant.REGEX_COLOR_CIRCLE.test(color) &&
      ![
        'colorFontTicket2Bottom',
        'colorFontTicket2Center',
        'colorFontTicket2Top',
        'colorFontTicket1Bottom',
        'colorFontTicket1Center',
        'colorFontTicket1Top'
      ].includes(prop)
    ) {
      this.ticketSelected.isChooseDetailUpdate
        ? (this.ticketSelected.ticketDetailUpdate[prop] = color)
        : (this.ticketSelected.ticketDetail[prop] = color);
      this.ticketSelected.ticketDetail[prop] = color;
    }
    if (prop.includes('1')) {
      this.isPreview1 = true;
    } else if (prop.includes('2')) {
      this.isPreview1 = false;
    }
    this.handleFocusInput(prop);
    this.focusOn = prop;
  }

  /**
   * changeColorByPicker
   * @param prop
   * @param event
   */
  changeColorByPicker(prop: string, event: any) {
    if (prop.includes('1')) {
      this.isPreview1 = true;
    } else if (prop.includes('2')) {
      this.isPreview1 = false;
    }
    this.ticketSelected.isChooseDetailUpdate
      ? (this.ticketSelected.ticketDetailUpdate[`${prop}-input`] = false)
      : (this.ticketSelected.ticketDetail[`${prop}-input`] = false);
    this.focusOn = prop;
  }

  /**
   * handleFocusInput
   * @param prop
   */
  handleFocusInput(prop: string): void {
    const input = [
      'colorCircleUpper',
      'colorCircleRotation',
      'colorCircleLower',
      'colorFontTicket1Top',
      'colorFontTicket1Bottom',
      'colorFontTicket1Center',
      'colorFontTicket2Top',
      'colorFontTicket2Bottom',
      'colorFontTicket2Center'
    ];

    if (this.ticketSelected.isChooseDetailUpdate) {
      this.ticketSelected.ticketDetailUpdate[`${prop}-input`] = true;
      input.forEach(e => {
        if (e !== prop) {
          this.ticketSelected.ticketDetailUpdate[`${e}-input`] = false;
        }
      });
    } else {
      this.ticketSelected.ticketDetail[`${prop}-input`] = true;
      input.forEach(e => {
        if (e !== prop) {
          this.ticketSelected.ticketDetail[`${e}-input`] = false;
        }
      });
    }
  }

  /**
   * add Detail
   * @param property
   */
  public addDetail(property: string): void {
    this.ticketSelected.isChooseDetailUpdate
      ? (this.ticketSelected.ticketDetailUpdate[`isDetail${property}`] = !this.ticketSelected.ticketDetailUpdate[`isDetail${property}`])
      : (this.ticketSelected.ticketDetail[`isDetail${property}`] = !this.ticketSelected.ticketDetail[`isDetail${property}`]);
  }

  /**
   * Allow Drop
   * @param event
   */
  public allowDrop(event: any): void {
    if (!this.isEditTicketDetail) {
      return;
    }
    event.preventDefault();
  }

  /**
   * Drop media from PC
   * @param file
   * @param isImage1
   */
  public async dropMediaFromPC(e: any, isImage1: boolean): Promise<void> {
    const fileInput = e.target;
    const selectedFile = fileInput.files[0];
    if (!selectedFile || (!this.isEditTicketDetail && !this.isEditTicketDetailUpdate)) {
      return;
    }
    if (
      !(await this.validateImage(
        selectedFile,
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_FILE_SIZE_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_WIDTH_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_HEIGHT_TAB_APPLICATION)
      ))
    ) {
      if (isImage1 && this.isEditTicketDetail) {
        this.fileInput1.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetail) {
        this.fileInput2.nativeElement.value = null;
      } else if (isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput3.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput4.nativeElement.value = null;
      }
      return;
    }
    isImage1 ? (this.isPreview1 = true) : (this.isPreview1 = false);
    const mediaType = selectedFile?.name
      .split('.')
      .pop()
      .toLowerCase();
    if (!this.imageTypesAllowed.includes(mediaType)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: this.translateService.instant('index-word-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      if (isImage1 && this.isEditTicketDetail) {
        this.fileInput1.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetail) {
        this.fileInput2.nativeElement.value = null;
      } else if (isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput3.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput4.nativeElement.value = null;
      }
      return;
    }

    let fileDrop = {
      file: selectedFile,
      url: URL.createObjectURL(selectedFile),
      type: mediaType
    };
    let file = [selectedFile, fileDrop];
    const fileName = `${this.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    if (isImage1) {
      this.image1 = await this.convertFileToBase64(selectedFile as File);
    } else {
      this.image2 = await this.convertFileToBase64(selectedFile as File);
    }
    this.handleAfterDropMedia(mediaType, selectedFile, fileName, file, isImage1);
  }

  /**
   * validateImage
   * @param file
   * @param maxFileSize
   * @param maxWidth
   * @param maxHeight
   * @returns
   */
  validateImage(file: File, maxFileSize: number, maxWidth: number, maxHeight: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!file.type.startsWith('image/')) {
        resolve(false);
        return;
      }

      if (file?.size > maxFileSize * 1024 * 1024) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: Helper.formatString(
              this.translateService.instant(`ticket-editor.msg.maximum-file-size`),
              `${file?.name}`,
              `${maxFileSize}`
            )
          }
        });
        resolve(false);
        return;
      }

      const img = new Image();
      let imageLoaded = false;

      img.onload = () => {
        imageLoaded = true;
        if (img.width > maxWidth) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-width`),
                `${file?.name}`,
                `${maxWidth}`
              )
            }
          });
          resolve(false);
        } else if (img.height > maxHeight) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-height`),
                `${file?.name}`,
                `${maxHeight}`
              )
            }
          });
          resolve(false);
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        if (!imageLoaded) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.common-error')
            }
          });
          resolve(false);
        }
      };

      img.src = URL.createObjectURL(file);
    });
  }

  /**
   * convertFileToBase64
   * @param file
   * @returns
   */
  convertFileToBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  /**
   * Handle After Drop Media
   * @param mediaType
   * @param fileDrop
   * @param file
   * @param isImage1
   */
  private async handleAfterDropMedia(mediaType: any, fileDrop: any, fileName: any, file: any, isImage1: boolean): Promise<void> {
    let fileFromPC: Media = null;
    if (this.ticketSelected.isChooseDetailUpdate) {
      isImage1
        ? (this.ticketSelected.ticketDetailUpdate.image1 = fileDrop?.name)
        : (this.ticketSelected.ticketDetailUpdate.image2 = fileDrop?.name);
    } else {
      isImage1 ? (this.ticketSelected.ticketDetail.image1 = fileDrop?.name) : (this.ticketSelected.ticketDetail.image2 = fileDrop?.name);
    }
    this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType, isImage1);
  }

  /**
   * Show Image After Drop
   * @param file
   * @param fileFromPC
   * @param mediaType
   * @param isImage1
   */
  private async showImgAfterDrop(
    fileDrop: any,
    fileName: any,
    file: any,
    fileFromPC: Media,
    mediaType: any,
    isImage1: boolean
  ): Promise<void> {
    let mediaFromPC: Media = new ImageApplicaiton();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    if (this.ticketSelected.isChooseDetailUpdate) {
      isImage1
        ? (this.ticketSelected.ticketDetailUpdate.urlImage1 = mediaFromPC.url)
        : (this.ticketSelected.ticketDetailUpdate.urlImage2 = mediaFromPC.url);
    } else {
      isImage1
        ? (this.ticketSelected.ticketDetail.urlImage1 = mediaFromPC.url)
        : (this.ticketSelected.ticketDetail.urlImage2 = mediaFromPC.url);
    }
  }

  /**
   * validateFullsize
   * @param input
   * @param minLength
   * @param maxLength
   * @returns
   */
  validateFullsize(input: string, minLength: number, maxLength: number): boolean {
    if (input.length < minLength || input.length > maxLength) {
      return false;
    }
    return true;
  }

  /**
   * validateHalfsizeNumber
   * @param input
   * @returns
   */
  validateHalfsizeNumber(input: number, min: number, max: number): boolean {
    if (isNaN(input)) {
      return false;
    }
    return input >= min && input <= max;
  }

  /**
   * validateTimeFormat
   * @param input
   * @returns
   */
  validateTimeFormat(input: string): boolean {
    const regex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(input);
  }

  /**
   * validateColorCode
   * @param input
   * @returns
   */
  validateColorCode(input: string): boolean {
    const regex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;
    return regex.test(input);
  }

  /**
   *validateColorCode6
   * @param input
   * @returns
   */
  validateColorCode6(input: string): boolean {
    const regex = /^#([0-9A-Fa-f]{6})$/;
    return regex.test(input);
  }

  /**
   * getStringTranslate
   * @param msg
   * @param param
   * @param param2
   * @returns
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`ticket-editor.ticket-tab.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`ticket-editor.ticket-tab.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * validate input
   * @param prop
   */
  public validateInput(prop: string): void {
    const type = this.ticketSelected.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    switch (prop) {
      // case 'CompanyName':
      //   this.ticketSelected[type]['isValidateCompanyNameOriginal'] = this.ticketSelected[type].companyName?.original.trim().length
      //     ? false
      //     : true;
      //   break;
      case 'OperatorName':
        this.ticketSelected[type]['isValidateOperatorNameOriginal'] = this.ticketSelected[type].operatorId ? false : true;
        break;
      case 'AppName':
        this.ticketSelected[type]['isValidateAppName'] = this.ticketSelected[type].appId ? false : true;
        if (this.ticketSelected[type]['isValidateAppName']) {
          this.languages = Constant.LANGUAGES_SETTING.filter(e => this.languageSelected == e.translation_language_code);
        } else {
          const index = this.listApp.findIndex(e => e.appId == this.ticketSelected?.ticketDetail.appId);
          this.languages = Constant.LANGUAGES_SETTING.filter(e =>
            this.listApp[index].supportedLanguageTranslation?.includes(e.translation_language_code)
          );
        }
        break;
      case 'TicketType':
        this.ticketSelected[type]['isValidateTicketType'] = this.ticketSelected[type].ticketType?.trim().length ? false : true;
        if (!this.ticketSelected[type]['isValidateTicketType']) {
          if (this.ticketSelected[type].ticketType == 'ticket') {
            if (this.isCoupon) {
              this.setDefault(type);
            }
            this.isCoupon = false;
            this.ticketSelected[type].inUseOperation = 0;
          } else {
            this.setDefault(type);
            this.isCoupon = true;
            this.ticketSelected[type].inUseOperation = 2;
          }
        } else {
          if (this.isCoupon) {
            this.setDefault(type);
          }
          this.isCoupon = false;
          this.ticketSelected[type].inUseOperation = null;
        }
        break;
      case 'InUseOperation':
        if (this.ticketSelected[type].inUseOperation == 2) {
          this.setDefault(type);
        }
        break;
      case 'CouponCount':
        if (this.ticketSelected.ticketDetail.ticketType == 'coupon') {
          this.ticketSelected[type]['isValidateCouponCount'] = this.ticketSelected[type].couponCount ? false : true;
          if (this.ticketSelected[type]['isValidateCouponCount'] == false) {
            this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = this.validateHalfsizeNumber(
              this.ticketSelected[type].couponCount,
              1,
              100
            )
              ? false
              : true;
          }
        } else if (this.ticketSelected[type].couponCount) {
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = this.validateHalfsizeNumber(
            this.ticketSelected[type].couponCount,
            1,
            100
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
        }
        break;
      case 'UsingPeriod':
        if (this.ticketSelected[type].usingPeriod) {
          if (this.ticketSelected[type].usingPeriodType == 'D') {
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 7)
              ? false
              : true;
          } else if (this.ticketSelected[type].usingPeriodType == 'm') {
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = this.validateHalfsizeNumber(
              this.ticketSelected[type].usingPeriod,
              1,
              5999
            )
              ? false
              : true;
          }
        } else {
          this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
          this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
        }
        break;
      case 'AvailableActiveStartTime':
        if (this.ticketSelected[type].availableActiveStartTime) {
          this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = this.validateTimeFormat(
            this.ticketSelected[type].availableActiveStartTime
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
        }
        break;
      case 'AvailableActiveEndTime':
        if (this.ticketSelected[type].availableActiveEndTime) {
          this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = this.validateTimeFormat(
            this.ticketSelected[type].availableActiveEndTime
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
        }
        break;
      case 'AvailableShowEndTime':
        if (this.ticketSelected[type].availableShowEndTime) {
          this.ticketSelected[type]['isValidateAvailableShowEndTime'] = this.validateTimeFormat(
            this.ticketSelected[type].availableShowEndTime
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
        }
        break;
      default:
        if (prop.includes('TicketName') && !prop.includes('TicketNameShort')) {
          let language = prop.slice('TicketName'.length);
          if (!this.languageTranslation?.includes(language)) {
            return;
          }
          this.ticketSelected[type][`isValidate${prop}`] = this.ticketSelected[type].ticketName[language]?.trim().length ? false : true;
          if (!this.ticketSelected[type][`isValidate${prop}`]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].ticketName[language],
              1,
              100
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.includes('TicketNameShort')) {
          let language = prop.slice('TicketNameShort'.length);
          if (!this.languageTranslation?.includes(language)) {
            return;
          }
          this.ticketSelected[type][`isValidate${prop}`] = this.ticketSelected[type].ticketNameShort[language]?.trim().length
            ? false
            : true;
          if (!this.ticketSelected[type][`isValidate${prop}`]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].ticketNameShort[language],
              1,
              50
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.startsWith('Subtitle')) {
          let language = prop.slice('Subtitle'.length);
          if (!this.languageTranslation?.includes(language)) {
            return;
          }
          if (this.ticketSelected[type].subtitle[language]?.trim().length) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].subtitle[language],
              1,
              100
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.includes('Note')) {
          let language = prop.slice('Note'.length);
          if (!this.languageTranslation?.includes(language)) {
            return;
          }
          this.ticketSelected[type][`isValidate${prop}`] = this.ticketSelected[type].note[language]?.trim().length ? false : true;
          if (!this.ticketSelected[type][`isValidate${prop}`]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].note[language],
              1,
              1000
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.includes('ActivateMessage')) {
          let language = prop.slice('ActivateMessage'.length);
          if (!this.languageTranslation?.includes(language)) {
            return;
          }
          this.ticketSelected[type][`isValidate${prop}`] = this.ticketSelected[type].activateMessage[language]?.trim().length
            ? false
            : true;
          if (!this.ticketSelected[type][`isValidate${prop}`]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].activateMessage[language],
              1,
              100
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
    }
  }

  /**
   * check Validation Price
   * @param properties
   * @returns
   */
  private checkValidationPrice(properties: string): boolean {
    const property = properties.split('.');
    if (this.ticketSelected.isChooseDetailUpdate) {
      return (
        this.ticketSelected.ticketDetailUpdate[property[0]][property[1]] == null ||
        this.ticketSelected.ticketDetailUpdate[property[0]][property[1]] < 0 ||
        this.ticketSelected.ticketDetailUpdate[property[0]][property[1]] > 9999
      );
    } else {
      return (
        this.ticketSelected.ticketDetail[property[0]][property[1]] == null ||
        this.ticketSelected.ticketDetail[property[0]][property[1]] < 0 ||
        this.ticketSelected.ticketDetail[property[0]][property[1]] > 9999
      );
    }
  }

  /**
   * changePreview
   */
  public changePreview(isPreview1: boolean): void {
    this.isPreview1 = isPreview1;
  }

  /**
   * focusOut
   */
  focusOut(str?: string): void {
    this.focusOn = '';
    if (str) {
      this.validateInput(`${str}`);
    }
  }

  /**
   * convertColorToRgba
   * @param event
   * @param prop
   */
  convertColorToRgba(event: any, prop: string): void {
    // Remove the '#' if it's present
    let hexColor = event.color.replace('#', '');

    // Convert opacity to a valid range (0 to 1)
    const validOpacity = Math.max(0, Math.min(1, +event.opacity));

    // Convert the hex color to RGB
    const bigint = parseInt(hexColor, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Convert the components to hexadecimal and pad with zeros if needed
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    const hexOpacity = Math.round(validOpacity * 255)
      .toString(16)
      .padStart(2, '0');

    // Create the 8-character hex color code
    const hexWithOpacity = `#${hexR}${hexG}${hexB}${hexOpacity}`;
    this.ticketSelected.isChooseDetailUpdate
      ? (this.ticketSelected.ticketDetailUpdate[`${prop}`] = hexWithOpacity.toLocaleUpperCase())
      : (this.ticketSelected.ticketDetail[`${prop}`] = hexWithOpacity.toLocaleUpperCase());
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * changeDetailUpdate
   * @param isChooseDetailUpdate
   */
  changeDetailUpdate(isChooseDetailUpdate: boolean): void {
    this.ticketSelected.isChooseDetailUpdate = isChooseDetailUpdate;
    if (this.isEditTicketDetail || this.isEditTicketDetailUpdate) {
      this.isEditTicketDetail = isChooseDetailUpdate ? false : true;
      this.isEditTicketDetailUpdate = isChooseDetailUpdate ? true : false;
    }
  }

  /**
   * Set Date
   * @param event
   * @param itemName
   */
  convertToDate(date: string, isUpdateAt?: boolean): Date {
    if (!date) {
      return null;
    }
    if (isUpdateAt) {
      return new Date(this.date);
    }
    return new Date(date);
  }

  /**
   * changeTitleFormData
   */
  changeTitleFormData() {
    if (this.titleFormData) {
      switch (this.titleFormData) {
        case this.titleAdd:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add');
          break;
        case this.titleAddSchedule:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
          break;
        case this.titleEdit:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit');
          break;
        case this.titleEditSchedule:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
          break;
        case this.titleDuplicate:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate');
          break;
      }
    }
  }

  translateTicket(): void {
    // const type = this.ticketSelected.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    // if (this.ticketSelected[`${type}`].ticketName.original) {
    //   const keyLanguage = this.languageKey == 'en' ? this.languageKey : 'ja';
    //   const targetCode = [];
    //   for(let property of Object.keys(this.ticketSelected[`${type}`])) {
    //     if(property.includes("isCheckTicketName") && this.ticketSelected[`${type}`][`${property}`]) {
    //     targetCode.push(property.slice(-2));
    //     }
    //   }
    //   if(targetCode.length) {
    //     let payload = {
    //       base_language_code: keyLanguage,
    //       text: Helper.encodeHTMLAnnouncementManager(this.ticketSelected[`${type}`].ticketName.original),
    //       source_language_code: keyLanguage,
    //       target_language_code: targetCode,
    //       glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_ticket_edito` : ''
    //     };
    //   }
    // }
  }

  changeCheckTranslation(property: string, caseValue: string): void {
    let type = this.ticketSelected.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    if (caseValue == 'TicketName') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('TicketName'.length);
      if (!Object.keys(this.ticketSelected[type]?.ticketName).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].ticketName[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateTicketName') && !e.includes('isValidateTicketNameOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'TicketNameShort') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('TicketNameShort'.length);
      if (!Object.keys(this.ticketSelected[type]?.ticketNameShort).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].ticketNameShort[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateTicketNameShort') && !e.includes('isValidateTicketNameShortOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'Subtitle') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('Subtitle'.length);
      if (!Object.keys(this.ticketSelected[type]?.subtitle).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].subtitle[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateSubtitle') && !e.includes('isValidateSubtitleOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'Note') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('Note'.length);
      if (!Object.keys(this.ticketSelected[type]?.note).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].note[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateNote') && !e.includes('isValidateNoteOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'ActivateMessage') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('ActivateMessage'.length);
      if (!Object.keys(this.ticketSelected[type]?.activateMessage).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].activateMessage[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateActivateMessage') && !e.includes('isValidateActivateMessageOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    }
  }

  /**
   * checkDisplayInput
   * @returns
   */
  checkDisplayInput(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (this.titleEdit) {
      return false;
    }
    if (this.titleAdd || this.titleDuplicate) {
      return true;
    }
  }

  /**
   * checkDisplayDate
   * @returns
   */
  checkDisplayDate(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (this.titleEdit) {
      return false;
    }
    if (this.titleAdd || this.titleDuplicate || this.titleEditSchedule || this.titleAddSchedule) {
      return true;
    }
  }

  /**
   * getOperatorName
   * @param operator
   * @returns
   */
  getOperatorName(operator: any): string {
    if (this.languageKey == 'en') {
      return operator.operatorName.en ? operator.operatorName.en : operator.operatorId;
    } else {
      return operator.operatorName.ja ? operator.operatorName.ja : operator.operatorId;
    }
  }

  /**
   * getValidityPeriod
   * @param ticket
   * @returns
   */
  getValidityPeriod(ticket: Ticket): string {
    let type = ticket.usingPeriodType == 'D' ? '日' : '分';
    if (ticket.usingPeriod) {
      return `${ticket.usingPeriod}${type}`;
    } else {
      return '';
    }
  }

  /**
   * setDate
   * @param event
   * @param itemName
   */
  setDate(event: any, itemName: string): void {
    const type = this.ticketSelected.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    if (event) {
      switch (itemName) {
        case 'enableOn[0]':
          this.ticketSelected[type].enableOn[0] = event;
          break;
        case 'enableOn[1]':
          if (!this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1]) {
            this.ticketSelected[type].enableOn = ['', event];
          } else if (this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1]) {
            this.ticketSelected[type].enableOn.push(event);
          } else {
            this.ticketSelected[type].enableOn[1] = event;
          }
          break;
        case 'disableOn[0]':
          this.ticketSelected[type].disableOn[0] = event;
          break;
        case 'disableOn[1]':
          if (!this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1]) {
            this.ticketSelected[type].disableOn = ['', event];
          } else if (this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1]) {
            this.ticketSelected[type].disableOn.push(event);
          } else {
            this.ticketSelected[type].disableOn[1] = event;
          }
          break;
        default:
          break;
      }
    }

    this.ticketSelected[type]['isValidateEnableOn0'] =
      !this.ticketSelected[type]?.enableOn?.[0] && this.ticketSelected[type]?.enableOn?.[1] ? true : false;
    this.ticketSelected[type]['isValidateEnableOn1'] =
      this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1] ? true : false;
    if (this.ticketSelected[type]?.enableOn?.[0] && this.ticketSelected[type]?.enableOn?.[1]) {
      const dateEnableOnStart = new Date(this.ticketSelected[type]?.enableOn?.[0]);
      const dateEnableOnEnd = new Date(this.ticketSelected[type]?.enableOn?.[1]);
      this.ticketSelected[type]['isValidateEnableOnValid'] = dateEnableOnStart > dateEnableOnEnd ? true : false;
    }

    this.ticketSelected[type]['isValidateDisableOn0'] =
      !this.ticketSelected[type]?.disableOn?.[0] && this.ticketSelected[type]?.disableOn?.[1] ? true : false;
    this.ticketSelected[type]['isValidateDisableOn1'] =
      this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1] ? true : false;
    if (this.ticketSelected[type]?.disableOn?.[0] && this.ticketSelected[type]?.disableOn?.[1]) {
      const dateDisableOnStart = new Date(this.ticketSelected[type]?.disableOn?.[0]);
      const dateDisableOnEnd = new Date(this.ticketSelected[type]?.disableOn?.[1]);
      this.ticketSelected[type]['isValidateDisableOnValid'] = dateDisableOnStart > dateDisableOnEnd ? true : false;
    }
  }

  /**
   * getInUseOperation
   * @param input
   * @returns
   */
  getInUseOperation(input: number): string {
    let inUseOperation;
    if (input == undefined || input == null) {
      return;
    }
    if (input == 0) {
      inUseOperation = '有効化ボタン';
    } else if (input == 1) {
      inUseOperation = 'QR表示';
    } else {
      inUseOperation = 'QR読み込み・コード入力';
    }
    return inUseOperation;
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayScheduleAt(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 19).replace('T', ' ');
  }

  /**
   * changeTime
   * @param e
   */
  changeTime(e: any) {
    this.time = e;
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 28) {
      value = value.substring(0, 28) + '...';
    } else if (value.length > 50) {
      value = value.substring(0, 50) + '...';
    }
    return value;
  }

  /**
   * getAppName
   * @param appId
   * @returns
   */
  getAppName(appId: string): string {
    const index = this.listApp.findIndex(e => e.appId == appId);
    if (index == -1) {
      return '';
    }
    return this.listApp[index].name;
  }

  /**
   * getFileNameFromUrl
   * @param url
   * @returns
   */
  getFileNameFromUrl(url: string): string {
    if (!url || (url && url.length == 0)) {
      return '';
    }
    const lastSlashIndex = url?.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      return url;
    }
    const filenameWithExtension = url.substring(lastSlashIndex + 1, url.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    return filename;
  }

  /**
   * setDefault
   * @param type
   */
  setDefault(type): void {
    this.ticketSelected[type].usingPeriod = null;
    this.ticketSelected[type].usingPeriodType = 'D';
    this.ticketSelected[type].availableShowEndTime = null;
    this.ticketSelected[type].couponGrantingType = null;
    this.ticketSelected[type].couponCount = null;
    this.ticketSelected[type].colorCircleUpper = '#FFFFFFFF';
    this.ticketSelected[type].colorCircleLower = '#FFFFFFFF';
    this.ticketSelected[type].colorCircleRotation = '#000000FF';
    this.ticketSelected[type].colorFontTicket1Top = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket1Center = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket1Bottom = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket2Top = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket2Center = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket2Bottom = '#FFFFFF';
  }

  toLocaleUpperCaseColor(color: string): string {
    return color ? color.toLocaleUpperCase() : '';
  }
}

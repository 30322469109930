import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ContentDayScheduleReservation } from 'app/model/entity/ticket/content-day-schedule-reservation';
import { ScheduleDetail } from 'app/model/entity/ticket/schedule-detail';
import { ScheduleReservation } from 'app/model/entity/ticket/schedule-reservation';
import { TicketReservation } from 'app/model/entity/ticket/ticket-reservation';
import { RegexTime } from 'app/module/timetable-editor/timetable-editor.component';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ticket-editor-tab-reservation',
  templateUrl: './ticket-editor-tab-reservation.component.html',
  styleUrls: ['./ticket-editor-tab-reservation.component.scss']
})
export class TicketEditorTabReservationComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  selectedDay: ContentDayScheduleReservation; // selected day
  scheduleDetailSelecting: ScheduleDetail; // schedule detail selecting
  ticketSelecting: TicketReservation; // ticket selecting
  schedulesDisplay: ScheduleReservation[]; // schedules display
  scheduleSelecting: ScheduleReservation; // schedule selecting
  currentIndexScheduleDetail: number; // current index schedule detail
  currentDate: Date; // current date
  commonObject: Common; // common object
  selectedYear: number; // year selected
  selectedMonth: any; // month selected
  isNextMonth: boolean; // true if > finish month
  isPreviousMonth: boolean = true; // true if < start month
  nameEdit: string; // name edit
  noEdit: string; // no edit
  suffixEdit: string; // suffix edit
  tickets: Array<Ticket> = new Array<Ticket>(); // list ticket
  isEnlargePreview: boolean = true; // true if preview on
  timeDateLine: string = Constant.TIME_DATE_LINE_DEFAULT; // time date line
  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('schedule-registration.month-1'), key: 0 },
    { value: this.translateService.instant('schedule-registration.month-2'), key: 1 },
    { value: this.translateService.instant('schedule-registration.month-3'), key: 2 },
    { value: this.translateService.instant('schedule-registration.month-4'), key: 3 },
    { value: this.translateService.instant('schedule-registration.month-5'), key: 4 },
    { value: this.translateService.instant('schedule-registration.month-6'), key: 5 },
    { value: this.translateService.instant('schedule-registration.month-7'), key: 6 },
    { value: this.translateService.instant('schedule-registration.month-8'), key: 7 },
    { value: this.translateService.instant('schedule-registration.month-9'), key: 8 },
    { value: this.translateService.instant('schedule-registration.month-10'), key: 9 },
    { value: this.translateService.instant('schedule-registration.month-11'), key: 10 },
    { value: this.translateService.instant('schedule-registration.month-12'), key: 11 }
  );
  ticketReservations: TicketReservation[] = [
    {
      id: 1,
      name: 'Ticket1',
      scheduleReservations: [
        {
          id: 1,
          no: '111',
          suffix: 'aaa',
          name: 'scheduleReservations1',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [
            {
              columnsData: ['04:30:07', '', '', '1500', '750'],
              isActiveDays: [
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true
              ],
              isEditDays: [
                true,
                true,
                false,
                false,
                true,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
              ],
              contentDays: [
                {
                  id: 1,
                  day: 0,
                  fullDate: new Date('Sun Aug 27 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '27'
                },
                {
                  id: 2,
                  day: 1,
                  fullDate: new Date('Mon Aug 28 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '28'
                },
                {
                  id: 3,
                  day: 2,
                  fullDate: new Date('Tue Aug 29 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '29'
                },
                {
                  id: 4,
                  day: 3,
                  fullDate: new Date('Wed Aug 30 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '30'
                },
                {
                  id: 5,
                  day: 4,
                  fullDate: new Date('Thu Aug 31 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '31'
                },
                {
                  id: 6,
                  day: 5,
                  fullDate: new Date('Fri Sep 01 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: false,
                  isEdit: false,
                  dateInMonth: '01'
                },
                {
                  id: 7,
                  day: 6,
                  fullDate: new Date('Sat Sep 02 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: false,
                  isEdit: false,
                  dateInMonth: '02'
                },
                {
                  id: 8,
                  day: 0,
                  fullDate: new Date('Sun Sep 03 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '03'
                },
                {
                  id: 9,
                  day: 1,
                  fullDate: new Date('Mon Sep 04 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '04'
                },
                {
                  id: 10,
                  day: 2,
                  fullDate: new Date('Tue Sep 05 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '05'
                },
                {
                  id: 11,
                  day: 3,
                  fullDate: new Date('Wed Sep 06 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '06'
                },
                {
                  id: 12,
                  day: 4,
                  fullDate: new Date('Thu Sep 07 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '07'
                },
                {
                  id: 13,
                  day: 5,
                  fullDate: new Date('Fri Sep 08 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '08'
                },
                {
                  id: 14,
                  day: 6,
                  fullDate: new Date('Sat Sep 09 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '09'
                },
                {
                  id: 15,
                  day: 0,
                  fullDate: new Date('Sun Sep 10 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '10'
                },
                {
                  id: 16,
                  day: 1,
                  fullDate: new Date('Mon Sep 11 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '11'
                },
                {
                  id: 17,
                  day: 2,
                  fullDate: new Date('Tue Sep 12 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '12'
                },
                {
                  id: 18,
                  day: 3,
                  fullDate: new Date('Wed Sep 13 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '13'
                },
                {
                  id: 19,
                  day: 4,
                  fullDate: new Date('Thu Sep 14 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '14'
                },
                {
                  id: 20,
                  day: 5,
                  fullDate: new Date('Fri Sep 15 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '15'
                },
                {
                  id: 21,
                  day: 6,
                  fullDate: new Date('Sat Sep 16 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '16'
                },
                {
                  id: 22,
                  day: 0,
                  fullDate: new Date('Sun Sep 17 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '17'
                },
                {
                  id: 23,
                  day: 1,
                  fullDate: new Date('Mon Sep 18 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '18'
                },
                {
                  id: 24,
                  day: 2,
                  fullDate: new Date('Tue Sep 19 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '19'
                },
                {
                  id: 25,
                  day: 3,
                  fullDate: new Date('Wed Sep 20 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '20'
                },
                {
                  id: 26,
                  day: 4,
                  fullDate: new Date('Thu Sep 21 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '21'
                },
                {
                  id: 27,
                  day: 5,
                  fullDate: new Date('Fri Sep 22 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '22'
                },
                {
                  id: 28,
                  day: 6,
                  fullDate: new Date('Sat Sep 23 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '23'
                },
                {
                  id: 29,
                  day: 0,
                  fullDate: new Date('Sun Sep 24 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '24'
                },
                {
                  id: 30,
                  day: 1,
                  fullDate: new Date('Mon Sep 25 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '25'
                },
                {
                  id: 31,
                  day: 2,
                  fullDate: new Date('Tue Sep 26 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '26'
                },
                {
                  id: 32,
                  day: 3,
                  fullDate: new Date('Wed Sep 27 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '27'
                },
                {
                  id: 33,
                  day: 4,
                  fullDate: new Date('Thu Sep 28 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '28'
                },
                {
                  id: 34,
                  day: 5,
                  fullDate: new Date('Fri Sep 29 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '29'
                },
                {
                  id: 35,
                  day: 6,
                  fullDate: new Date('Sat Sep 30 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: false,
                  inactive: false,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '30'
                },
                {
                  id: 36,
                  day: 0,
                  fullDate: new Date('Sun Oct 01 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '1'
                },
                {
                  id: 37,
                  day: 1,
                  fullDate: new Date('Mon Oct 02 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '2'
                },
                {
                  id: 38,
                  day: 2,
                  fullDate: new Date('Tue Oct 03 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '3'
                },
                {
                  id: 39,
                  day: 3,
                  fullDate: new Date('Wed Oct 04 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '4'
                },
                {
                  id: 40,
                  day: 4,
                  fullDate: new Date('Thu Oct 05 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '5'
                },
                {
                  id: 41,
                  day: 5,
                  fullDate: new Date('Fri Oct 06 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '6'
                },
                {
                  id: 42,
                  day: 6,
                  fullDate: new Date('Sat Oct 07 2023 00:00:00 GMT+0700 (Indochina Time)'),
                  isOtherMonth: true,
                  inactive: true,
                  isActive: true,
                  isEdit: false,
                  dateInMonth: '7'
                }
              ],
              inValidRow: false,
              inValidRowDulicate: false,
              titleTooltip: null,
              isChecked: false
            },
            {
              columnsData: ['05:00:07', '', '', '1500', '750'],
              isActiveDays: [],
              isEditDays: [],
              contentDays: [],
              inValidRow: false,
              inValidRowDulicate: false,
              titleTooltip: null,
              isChecked: false
            },
            {
              columnsData: ['05:10:07', '', '', '1500', '750'],
              isActiveDays: [],
              isEditDays: [],
              contentDays: [],
              inValidRow: false,
              inValidRowDulicate: false,
              titleTooltip: null,
              isChecked: false
            },
            {
              columnsData: ['05:20:07', '', '', '1500', '750'],
              isActiveDays: [],
              isEditDays: [],
              contentDays: [],
              inValidRow: false,
              inValidRowDulicate: false,
              titleTooltip: null,
              isChecked: false
            },
            {
              columnsData: ['05:30:07', '', '', '1500', '750'],
              isActiveDays: [],
              isEditDays: [],
              contentDays: [],
              inValidRow: false,
              inValidRowDulicate: false,
              titleTooltip: null,
              isChecked: false
            }
          ],
          isEditing: false,
          headers: ['時刻', '便番号', '席数', '価格（大人）', '価格（子供）']
        },
        {
          id: 2,
          no: '222',
          suffix: 'bbb',
          name: 'scheduleReservations2',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 3,
          no: '333',
          suffix: 'ccc',
          name: 'scheduleReservations3',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 4,
          no: '444',
          suffix: 'ddd',
          name: 'scheduleReservations4',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 5,
          no: '111',
          suffix: 'aaa',
          name: 'scheduleReservations1',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 6,
          no: '222',
          suffix: 'bbb',
          name: 'scheduleReservations2',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 7,
          no: '333',
          suffix: 'ccc',
          name: 'scheduleReservations3',
          idTicket: 1,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        }
      ]
    },
    {
      id: 2,
      name: 'Ticket2',
      scheduleReservations: [
        {
          id: 1,
          no: '555',
          suffix: 'eee',
          name: 'scheduleReservations5',
          idTicket: 2,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 2,
          no: '666',
          suffix: 'fff',
          name: 'scheduleReservations6',
          idTicket: 2,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 3,
          no: '777',
          suffix: 'ggg',
          name: 'scheduleReservations7',
          idTicket: 2,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 4,
          no: '888',
          suffix: 'hhh',
          name: 'scheduleReservations8',
          idTicket: 2,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        }
      ]
    },
    {
      id: 3,
      name: 'Ticket3',
      scheduleReservations: [
        {
          id: 1,
          no: '999',
          suffix: 'iii',
          name: 'scheduleReservations9',
          idTicket: 3,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 2,
          no: '123',
          suffix: 'kkk',
          name: 'scheduleReservations10',
          idTicket: 3,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 3,
          no: '234',
          suffix: 'lll',
          name: 'scheduleReservations11',
          idTicket: 3,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 4,
          no: '345',
          suffix: 'mmm',
          name: 'scheduleReservations12',
          idTicket: 3,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        }
      ]
    },
    {
      id: 4,
      name: 'Ticket4',
      scheduleReservations: [
        {
          id: 1,
          no: '567',
          suffix: 'nnn',
          name: 'scheduleReservations13',
          idTicket: 4,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 2,
          no: '678',
          suffix: 'ooo',
          name: 'scheduleReservations14',
          idTicket: 4,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 3,
          no: '789',
          suffix: 'ppp',
          name: 'scheduleReservations15',
          idTicket: 4,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        },
        {
          id: 4,
          no: '890',
          suffix: 'qqq',
          name: 'scheduleReservations16',
          idTicket: 4,
          isChecking: false,
          scheduleDetails: [],
          isEditing: false,
          headers: []
        }
      ]
    }
  ];
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component
  constructor(
    private menuActionService: MenuActionService,
    private translateService: TranslateService,
    private dataService: DataService,
    private dialogService: DialogService,
    private ticketEditorService: TicketEditorService,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {
    this.subscriptions.push(
      // Action add
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.RESERVATION_ENUM) {
          this.addReservation();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.RESERVATION_ENUM) {
          this.editReservation();
        }
      }),
      // Action Duplicate
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.RESERVATION_ENUM) {
          this.duplicateReservation();
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.RESERVATION_ENUM) {
          this.deleteReservation();
        }
      }),
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.listMonth = Array(
          { value: this.translateService.instant('timetable-editor.month-1'), key: 0 },
          { value: this.translateService.instant('timetable-editor.month-2'), key: 1 },
          { value: this.translateService.instant('timetable-editor.month-3'), key: 2 },
          { value: this.translateService.instant('timetable-editor.month-4'), key: 3 },
          { value: this.translateService.instant('timetable-editor.month-5'), key: 4 },
          { value: this.translateService.instant('timetable-editor.month-6'), key: 5 },
          { value: this.translateService.instant('timetable-editor.month-7'), key: 6 },
          { value: this.translateService.instant('timetable-editor.month-8'), key: 7 },
          { value: this.translateService.instant('timetable-editor.month-9'), key: 8 },
          { value: this.translateService.instant('timetable-editor.month-10'), key: 9 },
          { value: this.translateService.instant('timetable-editor.month-11'), key: 10 },
          { value: this.translateService.instant('timetable-editor.month-12'), key: 11 }
        );
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
  }

  ngOnInit(): void {
    this.selectedYear = this.currentDate.getFullYear();
    this.selectedMonth = this.listMonth.find(month => month.key == 8).key;
    // Select data
    this.selectTicketReservation(this.ticketReservations[0]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  /**
   * select day
   * @param contentDay
   */
  selectDay(contentDay: ContentDayScheduleReservation): void {
    if (contentDay.isOtherMonth || contentDay.inactive) {
      return;
    }
    this.selectedDay = contentDay;
  }

  /**
   * select ticket reservation
   * @param ticket
   */
  selectTicketReservation(ticket: TicketReservation): void {
    this.ticketSelecting = ticket;
    this.schedulesDisplay = _.cloneDeep(this.ticketSelecting.scheduleReservations);
    if (this.schedulesDisplay) {
      this.selectSchedule(this.schedulesDisplay[0], null);
    }
  }

  /**
   * select schedule
   * @param schedule
   * @param event
   * @returns
   */
  selectSchedule(schedule: ScheduleReservation, event): void {
    if (
      this.scheduleSelecting?.id == schedule.id ||
      this.scheduleSelecting?.isEditing ||
      event?.target?.id === 'checkBoxScheduleReservation' ||
      schedule?.id == this.scheduleSelecting?.id
    ) {
      return;
    }
    this.scheduleSelecting = schedule;
    this.currentIndexScheduleDetail = 0;
    this.selectScheduleDetail(this.currentIndexScheduleDetail, null);
  }

  /**
   * select schedule detail
   * @param id
   * @param event
   */
  selectScheduleDetail(index: number, event): void {
    if (index < 0 || index >= this.scheduleSelecting?.scheduleDetails?.length) {
      return;
    }
    this.scheduleDetailSelecting = this.scheduleSelecting.scheduleDetails[index];
    this.currentIndexScheduleDetail = index;
  }

  selectMonth(month: any): void {
    this.selectedMonth = +month;
  }

  /**
   * show prev month
   */
  showPrevMonth(): void {}

  /**
   * show next month
   */
  showNextMonth(): void {}

  /**
   * change checking schedule detail
   * @param id
   * @param event
   */
  changeCheckingScheduleDetail(id: number, event): void {}

  /**
   * add
   */
  addReservation(): void {
    if (this.scheduleSelecting && this.scheduleSelecting.isEditing) {
      return;
    }
    this.noEdit = Constant.EMPTY;
    this.suffixEdit = Constant.EMPTY;
    this.nameEdit = Constant.EMPTY;
    let scheduleReservationNew = new ScheduleReservation(null, this.noEdit, this.suffixEdit, this.nameEdit);
    scheduleReservationNew.isEditing = true;
    this.schedulesDisplay.push(scheduleReservationNew);
    this.scheduleSelecting = scheduleReservationNew;
    this.dataService.sendData([Constant.IS_EDIT_SCHEDULE_RESERVATION, this.scheduleSelecting.isEditing]);
  }

  /**
   * edit
   */
  editReservation(): void {
    if (!this.scheduleSelecting) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.scheduleSelecting && this.scheduleSelecting.isEditing) {
      return;
    }
    this.noEdit = this.scheduleSelecting.no;
    this.suffixEdit = this.scheduleSelecting.suffix;
    this.nameEdit = this.scheduleSelecting.name;
    this.scheduleSelecting.isEditing = true;
    const index = this.schedulesDisplay.findIndex(schedule => schedule.id == this.scheduleSelecting.id);
    if (index != -1) {
      this.schedulesDisplay[index].isEditing = true;
    }
    this.dataService.sendData([Constant.IS_EDIT_SCHEDULE_RESERVATION, this.scheduleSelecting.isEditing]);
  }

  /**
   * duplicate
   */
  duplicateReservation(): void {
    if (!this.scheduleSelecting) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.scheduleSelecting.isEditing) {
      return;
    }
    this.noEdit = this.scheduleSelecting.no;
    this.suffixEdit = this.scheduleSelecting.suffix;
    this.nameEdit = this.scheduleSelecting.name;
    let schedule = new ScheduleReservation(null, this.noEdit, this.suffixEdit, this.nameEdit);
    schedule.isEditing = true;
    schedule.isChecking = false;
    schedule.idTicket = this.scheduleSelecting.idTicket;
    schedule.scheduleDetails = this.scheduleSelecting.scheduleDetails;
    this.scheduleSelecting = schedule;
    this.schedulesDisplay.push(this.scheduleSelecting);
    this.dataService.sendData([Constant.IS_EDIT_SCHEDULE_RESERVATION, this.scheduleSelecting.isEditing]);
  }

  /**
   * delete
   */
  deleteReservation(): void {
    const checkingSchedules = this.schedulesDisplay.filter(schedule => schedule.isChecking);
    if (!checkingSchedules?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    if (this.scheduleSelecting.isEditing) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant(`timetable-editor.delete-checked-timetables`),
          button1: this.translateService.instant('timetable-editor.yes'),
          button2: this.translateService.instant('timetable-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.handleDeleteSchedules(checkingSchedules);
      }
    );
  }

  /**
   * handle delete schedules
   * @param checkingSchedules
   */
  handleDeleteSchedules(checkingSchedules: ScheduleReservation[]): void {
    this.schedulesDisplay = this.schedulesDisplay.filter(schedule => !schedule.isChecking);
    if (this.schedulesDisplay?.length) {
      if (this.scheduleSelecting.isChecking) {
        this.selectSchedule(this.schedulesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      }
    } else {
      this.scheduleSelecting = undefined;
    }
  }

  /**
   * cancel save schedule
   */
  cancelSaveSchedule(): void {
    this.scheduleSelecting.isEditing = false;
    const index = this.schedulesDisplay.findIndex(schedule => schedule.id == this.scheduleSelecting.id);
    if (index != -1) {
      this.schedulesDisplay[index].isEditing = false;
    }
    this.dataService.sendData([Constant.IS_EDIT_SCHEDULE_RESERVATION, this.scheduleSelecting.isEditing]);
    if (!this.scheduleSelecting.id) {
      this.schedulesDisplay.pop();
      if (this.schedulesDisplay?.length) {
        this.selectSchedule(this.schedulesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      } else {
        this.scheduleSelecting = undefined;
      }
    } else {
      this.selectSchedule(this.scheduleSelecting, null);
    }
  }

  /**
   * change checking schedule
   * @param scheduleId
   * @param event
   */
  changeCheckingSchedule(scheduleId: number, event): void {
    event.stopPropagation();
    const index = this.schedulesDisplay.findIndex(schedule => schedule.id == scheduleId);
    this.schedulesDisplay[index].isChecking = !this.schedulesDisplay[index].isChecking;
    if (this.scheduleSelecting.id == this.schedulesDisplay[index].id) {
      this.scheduleSelecting.isChecking = this.schedulesDisplay[index].isChecking;
    }
  }

  /**
   * save schedule
   */
  saveSchedule(): void {
    const no = this.noEdit;
    const suffix = this.suffixEdit;
    const name = this.nameEdit;
    this.scheduleSelecting.no = no;
    this.scheduleSelecting.suffix = suffix;
    this.scheduleSelecting.name = name;
    // edit schedule
    this.scheduleSelecting.isEditing = false;
    this.dataService.sendData([Constant.IS_EDIT_SCHEDULE_RESERVATION, false]);
    // edit schedule
    if (this.scheduleSelecting.id) {
      const index = this.schedulesDisplay.findIndex(schedule => schedule.id == this.scheduleSelecting.id);
      this.schedulesDisplay[index] = _.cloneDeep(this.scheduleSelecting);
      this.selectSchedule(this.schedulesDisplay[index], null);
      this.ticketSelecting.scheduleReservations = _.cloneDeep(this.schedulesDisplay);
      // add schedule
    } else {
      this.scheduleSelecting.id = 1;
      if (this.schedulesDisplay && this.schedulesDisplay.length > 0) {
        this.scheduleSelecting.id = this.getIdMaxSchedule() + 1;
      }
      this.scheduleSelecting.idTicket = this.ticketSelecting.id;
      this.scheduleSelecting.isChecking = false;
      this.schedulesDisplay[this.schedulesDisplay.length - 1] = _.cloneDeep(this.scheduleSelecting);
      this.selectSchedule(this.schedulesDisplay[this.schedulesDisplay.length - 1], null);
      this.ticketSelecting.scheduleReservations[this.ticketSelecting.scheduleReservations.length - 1] = _.cloneDeep(this.scheduleSelecting);
    }
    this.handleDisplayTicket();
  }

  /**
   * get id max schedule
   */
  getIdMaxSchedule(): number {
    const idSchedule = this.schedulesDisplay.map(schedule => schedule.id).filter(id => id);
    const maxId = Math.max(...idSchedule);
    return maxId;
  }

  /**
   * handle display ticket
   */
  handleDisplayTicket(): void {
    const indexTicket = this.tickets.findIndex(ticket => ticket.id == this.ticketSelecting.id);
    this.tickets[indexTicket] = _.cloneDeep(this.ticketSelecting);
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * upload excel
   * @param event
   */
  uploadExcel(event): Promise<void> {
    const selectedFiles: File[] = event.target.files;
    const typeFiles = ['xlsx'];
    for (const file of selectedFiles) {
      const typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (!typeFiles.includes(typeName)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-merge.msg.invalid-file')
          }
        });
        return;
      }
    }
    this.ticketEditorService.readExcelsFromClient(selectedFiles, this.getRegexTime(), this.timeDateLine).subscribe(
      scheduleReservation => {
        this.handleAfterGetScheduleReservation(scheduleReservation, 'add');
      },
      error => {
        this.handleShowErrorMessageFromServerWhenImport(error);
      }
    );
    // reset input file value
    const element = document.getElementById('importedFileScheduleReservation') as HTMLInputElement;
    element.value = null;
  }
  /**
   * get regex time
   */
  getRegexTime(): RegexTime {
    const regexTimeMinute1 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE1_REGEX, this.timeDateLine);
    const regexTimeMinute2 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE2_REGEX, this.timeDateLine);
    const regexTimeSecond = Helper.formatTimeRegex(Constant.FORMAT_TIME_SECOND_REGEX, this.timeDateLine);
    return new RegexTime(regexTimeMinute1, regexTimeMinute2, regexTimeSecond);
  }

  /**
   * Handle show error message from server when import
   *
   * @param error
   */
  handleShowErrorMessageFromServerWhenImport(error: any): void {}

  /**
   * handleAfterReadFile
   * @param scheduleDB
   */
  handleAfterGetScheduleReservation(scheduleDB: any, action?: string): void {
    if (action == 'add') {
      const schedulestemp = Helper.convertScheduleReservationAfterImport(scheduleDB, this.timeDateLine, this.getIdMaxSchedule());
      const idsAdd = schedulestemp.map(item => item.id);
      const scheduleClone = _.cloneDeep(this.ticketSelecting.scheduleReservations);
      _.remove(idsAdd, function(id) {
        return scheduleClone.map(element => element.id).includes(id);
      });
      let listScheduleAdd = new Array<ScheduleReservation>();
      schedulestemp?.forEach(data => {
        if (idsAdd?.includes(data.id)) {
          listScheduleAdd.push(data);
        }
      });
      this.convertSchedule(listScheduleAdd);
      schedulestemp?.forEach(schedule => {
        if (!this.ticketSelecting.scheduleReservations.find(data => data.id === schedule.id)) {
          this.ticketSelecting.scheduleReservations.push(schedule);
        }
      });
      this.convertSchedule(this.ticketSelecting.scheduleReservations);
      this.schedulesDisplay = [...this.schedulesDisplay, ...listScheduleAdd];
    } else {
      this.handleDisplayImport(scheduleDB);
      this.convertSchedule(this.ticketSelecting.scheduleReservations);
    }
  }
  /**
   * convertSchedule
   * @param schedules
   */
  convertSchedule(schedules: ScheduleReservation[]): void {
    schedules?.forEach(schedule => {
      schedule.scheduleDetails?.forEach(scheduleDetail => {
        if (!scheduleDetail.contentDays?.length) {
          scheduleDetail.contentDays = this.getCalendarsForSchedule(schedule.no);
          if (scheduleDetail.contentDays.length) {
            const index = scheduleDetail.contentDays.findIndex(day => day.isOtherMonth == false);
            let indexActive = 0;
            for (let i = index; i < index + Helper.getDaysInMonth(schedule?.no); i++) {
              scheduleDetail.contentDays[i].isActive = scheduleDetail.isActiveDays[indexActive];
              if (scheduleDetail?.isEditDays?.length) {
                scheduleDetail.contentDays[i].isEdit = scheduleDetail?.isEditDays[indexActive] ? true : false;
              }
              indexActive++;
            }
          }
        }
      });
    });
  }
  /**
   * handle display import
   * @param schedulesresult
   */
  handleDisplayImport(scheduleResult: any): void {
    const oldSchedules = _.cloneDeep(this.ticketSelecting?.scheduleReservations);
    this.ticketSelecting.scheduleReservations = Helper.convertScheduleReservationAfterImport(
      scheduleResult,
      this.timeDateLine,
      this.getIdMaxSchedule()
    );
    const schedulesAdd = _.cloneDeep(this.ticketSelecting.scheduleReservations);
    const displayTemp = _.cloneDeep(this.schedulesDisplay);
    let IdsDisplay = [...new Set(displayTemp.map(item => item.id))];
    this.schedulesDisplay = new Array<ScheduleReservation>();
    _.remove(schedulesAdd, function(schedule) {
      return oldSchedules.map(item => item.id).includes(schedule.id);
    });
    IdsDisplay = [...IdsDisplay, ...schedulesAdd?.map(data => data.id)];
    IdsDisplay?.forEach(id => {
      this.schedulesDisplay.push(this.ticketSelecting.scheduleReservations.find(schedule => schedule.id == id));
    });
  }
  /**
   * get calendars for schedule
   * @param no
   * @returns
   */
  getCalendarsForSchedule(no: String): Array<ContentDayScheduleReservation> {
    const month = +no.substring(2) - 1;
    const year = +`20${no.substring(0, 2)}`;
    const calendars = Helper.getCalendarsScheduleMerge(year, month);
    return Helper.getCalendarsByMonthYearSchedule(calendars, month, year, this.commonObject);
  }
}

import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'preview-tab-application',
  templateUrl: './preview-tab-application.component.html',
  styleUrls: ['./preview-tab-application.component.scss']
})
export class PreviewTabApplicationComponent implements OnInit {
  @Input() isEnlargePreview: boolean; //returns true as Set zoom preview
  @Input() focusOn: string;
  WIDTH_PREVIEW_DEFAULT = 467;
  HEIGHT_PREVIEW_DEFAULT = 700;
  HEIGHT_CONTENT_DEFAULT = 640;
  urlTopPhoto: string; // url top photo preview
  height: string; // height content preview
  widthData: string;
  heightData: string;
  dataDisplaySize: DisplaySize;
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  /**
   * Set data Preview
   * @param data
   */
  setDataPreview(data: DataPreview): void {
    this.urlTopPhoto = data.topPhoto;
    this.elementRef.nativeElement.style.setProperty('--main-color', data.mainColor);
    this.elementRef.nativeElement.style.setProperty('--sub-color', data.subColor);
    this.elementRef.nativeElement.style.setProperty('--background-color', data.backgroundColor);
    this.elementRef.nativeElement.style.setProperty('--border-color', data.borderColor);
    this.elementRef.nativeElement.style.setProperty('--inactive-color', data.inActiveColor);
  }

  /**
   * Set display size
   * @param data
   */
  setDisplaySizePreview(data: DisplaySize): void {
    this.dataDisplaySize = data;
    this.widthData = `${data.width}px`;
    this.heightData = `${data.height}px`;
    const heightNumber = this.HEIGHT_PREVIEW_DEFAULT - data.height;
    this.height = `${this.HEIGHT_CONTENT_DEFAULT - heightNumber}px`;
  }
}

export interface DataPreview {
  topPhoto: string;
  mainColor: string;
  subColor: string;
  backgroundColor: string;
  borderColor: string;
  inActiveColor: string;
}

export interface DisplaySize {
  width: number;
  height: number;
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Ticket } from 'app/model/entity/ticket';

@Component({
  selector: 'preview-tab-ticket',
  templateUrl: './preview-tab-ticket.component.html',
  styleUrls: ['./preview-tab-ticket.component.scss']
})
export class PreviewTabTicketComponent implements OnInit, OnChanges {
  @Input() ticketSelected: Ticket;
  @Input() isPreview1: boolean;
  @Input() isChooseDetailUpdate: boolean;
  @Input() isResponsive: boolean;
  @Input() focusOn: string;
  @Input() languageSelected: string;
  @Input() languageKey: string;
  WIDTH_PREVIEW_DEFAULT = 428;
  HEIGHT_PREVIEW_DEFAULT = 667;
  widthData: string;
  heightData: string;
  dataDisplaySize: DisplaySize;
  backgroundImageUrl: string;
  colorCircleLower: string;
  colorCircleUpper: string;
  colorCircleRotation: string;
  textColorTop: string;
  textColorBottom: string;
  textColorCircle: string;
  textColorTotalCount: string;
  adultCount: number;
  customCount1: number;
  customCount2: number;
  customCount3: number;
  totalCount: number;
  isOnlyAdult: boolean;
  scaleX: number;
  scaleY: number;
  type: string;
  ticketNameShort: any;
  subtitle: any;
  constructor(private sanitizer: DomSanitizer) {
    this.dataDisplaySize = {
      width: 375,
      height: 667
    };
    this.scaleX = 1;
    this.scaleY = 1;
  }

  ngDoCheck() {
    this.colorCircleLower = this.ticketSelected[`${this.type}`].colorCircleLower;
    this.colorCircleUpper = this.ticketSelected[`${this.type}`].colorCircleUpper;
    this.colorCircleRotation = this.ticketSelected[`${this.type}`].colorCircleRotation;
    this.textColorBottom = this.isPreview1
      ? this.ticketSelected[`${this.type}`].colorFontTicket1Bottom
      : this.ticketSelected[`${this.type}`].colorFontTicket2Bottom;
    this.textColorTop = this.isPreview1
      ? this.ticketSelected[`${this.type}`].colorFontTicket1Top
      : this.ticketSelected[`${this.type}`].colorFontTicket2Top;
    this.textColorCircle = this.isPreview1
      ? this.ticketSelected[`${this.type}`].colorFontTicket1Center
      : this.ticketSelected[`${this.type}`].colorFontTicket2Center;
    this.adultCount = this.ticketSelected[`${this.type}`].adultCount;
    this.customCount1 = this.ticketSelected[`${this.type}`].customCount1;
    this.customCount2 = this.ticketSelected[`${this.type}`].customCount2;
    this.customCount3 = this.ticketSelected[`${this.type}`].customCount3;
    this.totalCount = this.ticketSelected[`${this.type}`].totalCount;
    this.isOnlyAdult = this.customCount1 || this.customCount2 || this.customCount3 ? false : true;
    this.textColorTotalCount = this.isOnlyAdult ? this.textColorCircle : '#ff2aff';
    this.ticketNameShort =
      this.ticketSelected[`${this.type}`]?.ticketNameShort &&
      this.ticketSelected[`${this.type}`]?.ticketNameShort[`${this.languageSelected}`]
        ? this.ticketSelected[`${this.type}`]?.ticketNameShort[`${this.languageSelected}`]
        : this.ticketSelected[`${this.type}`]?.ticketNameShort?.original;
    this.subtitle =
      this.ticketSelected[`${this.type}`]?.subtitle && this.ticketSelected[`${this.type}`]?.subtitle[`${this.languageSelected}`]
        ? this.ticketSelected[`${this.type}`]?.subtitle[`${this.languageSelected}`]
        : this.ticketSelected[`${this.type}`]?.subtitle?.original;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isChooseDetailUpdate) {
      this.type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    }
  }

  ngOnInit(): void {
    this.type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    this.backgroundImageUrl = this.ticketSelected.ticketDetail.image1;
  }

  /**
   * sanitized Background Image
   * @returns
   */
  sanitizedBackgroundImage(): SafeStyle {
    const imageUrl = this.isPreview1 ? this.ticketSelected[`${this.type}`].urlImage1 : this.ticketSelected[`${this.type}`].urlImage2;
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
    return sanitizedUrl;
  }

  /**
   * Set display size
   * @param data
   */
  setDisplaySizePreview(data: DisplaySize): void {
    this.dataDisplaySize = data;
    this.widthData = `${data.width}px`;
    this.heightData = `${data.height}px`;
    this.scaleX = 1;
    this.scaleY = 1;
    if (data.height < 667) {
      this.scaleY = data.height / 667;
      this.heightData = '667px';
    }
  }
}

export interface DisplaySize {
  width: number;
  height: number;
}

<div id="root"> <div class="screen-container" [class.scroll-x]="dataDisplaySize?.width > WIDTH_PREVIEW_DEFAULT" [class.scroll-y]="dataDisplaySize?.height > HEIGHT_PREVIEW_DEFAULT"> <div class="app-container" [ngStyle]="{ width: widthData, height: heightData }"> <div class="content-header-container scrollable" [ngStyle]="{ height: height }"> <div class="styles_imgContainer__Hksjp"> <img *ngIf="urlTopPhoto" [src]="urlTopPhoto | safeUrl" alt=""/> </div> <p class="styles_title__FqkHN">販売中のチケット</p> <div class="styles_ticketList__zScyy"> <div class="styles_ticketsContainer__2mKyv" [ngClass]="{ 'hightlight-border': focusOn == 'mainColor' }"> <div class="styles_ticketContent__g42JC"> <p class="styles_ticketName__q4zjL">Sampleチケット</p> <div> <div class="styles_ticketSubtitle__RKno6">Sampleチケットの説明です</div> <div class="styles_ticketPrice__ZzEjb"> <span style="margin-right: 10px;">大人 / 2,000円</span><span style="margin-right: 10px;">カスタム1 / 1,000円</span><span style="margin-right: 10px;">カスタム2 / 1,500円</span><span style="margin-right: 10px;">カスタム3 / 1,700円</span> </div> </div> </div> </div> </div> </div> <div class="footer-container"> <a class="footer-item-border"> <svg id="_\u30EC\u30A4\u30E4\u30FC_2" data-name="\u30EC\u30A4\u30E4\u30FC 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.52 50.09" width="40" height="40"> <rect x="0" y="0" width="70" height="60" fill="none" stroke="red" stroke-width="1" stroke-dasharray="5" *ngIf="focusOn == 'mainColor'"></rect> <defs> <style> .cls-1-ic-buy-enabed {
                  fill: #fff;
                }
                .cls-2-ic-buy-enabed {
                  fill: var(--main-color);
                }
                .cls-3-ic-buy-enabed {
                  fill: var(--sub-color);
                } </style> </defs> <g id="text"> <g> <g> <g> <g> <path class="cls-1-ic-buy-enabed" d="m1.7,49.59c-.66,0-1.2-.54-1.2-1.2v-31.53c0-.66.54-1.2,1.2-1.2h53.16c.66,0,1.2.54,1.2,1.2v31.53c0,.66-.54,1.2-1.2,1.2H1.7Z"></path> <path class="cls-2-ic-buy-enabed" d="m54.86,16.17c.39,0,.7.31.7.7v31.53c0,.38-.32.7-.7.7H1.7c-.39,0-.7-.31-.7-.7v-31.53c0-.38.32-.7.7-.7h53.16m0-1H1.7c-.94,0-1.7.76-1.7,1.7v31.53c0,.94.76,1.7,1.7,1.7h53.16c.94,0,1.7-.76,1.7-1.7v-31.53c0-.94-.76-1.7-1.7-1.7h0Z"></path> <rect _ngcontent-kjm-c314="" _ngcontent-xng-c314="" x="30" y="0" width="40" height="40" fill="none" stroke="red" stroke-width="1" stroke-dasharray="5" *ngIf="focusOn == 'subColor'"></rect> </g> <path class="cls-2-ic-buy-enabed" d="m1.77,15.31c-1.08,0-1.58.84-1.58,1.5v31.59c0,.66.5,1.5,1.58,1.5h6.49V15.31H1.77Z"></path> </g> <circle class="cls-3-ic-buy-enabed" cx="52.34" cy="19.19" r="19.19"></circle> </g> <polygon class="cls-1-ic-buy-enabed" points="63.2 18.08 53.3 18.08 53.3 8.18 51.1 8.18 51.1 18.08 41.21 18.08 41.21 20.28 51.1 20.28 51.1 30.17 53.3 30.17 53.3 20.28 63.2 20.28 63.2 18.08"></polygon> </g> </g> </svg> <p style="font-weight: bold; font-size: 16px; margin-left: 6px; margin-top: 6px; color: rgb(0, 0, 0);">買う</p> </a> <a class="footer-item-border"> <svg id="_\u30EC\u30A4\u30E4\u30FC_2" data-name="\u30EC\u30A4\u30E4\u30FC 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.52 50.09" width="40" height="40"> <rect x="0" y="0" width="70" height="60" fill="none" stroke="red" stroke-width="1" stroke-dasharray="5" *ngIf="focusOn == 'inActiveColor'"></rect> <defs> <style> .cls-1-ic-use-disabled {
                  fill: #fff;
                }
                .cls-2-ic-use-disabled {
                  fill: var(--inactive-color);
                } </style> </defs> <g id="text"> <g> <g> <g> <g> <path class="cls-1-ic-use-disabled" d="m1.7,49.59c-.66,0-1.2-.54-1.2-1.2v-31.53c0-.66.54-1.2,1.2-1.2h53.16c.66,0,1.2.54,1.2,1.2v31.53c0,.66-.54,1.2-1.2,1.2H1.7Z"></path> <path class="cls-2-ic-use-disabled" d="m54.86,16.17c.39,0,.7.31.7.7v31.53c0,.38-.32.7-.7.7H1.7c-.39,0-.7-.31-.7-.7v-31.53c0-.38.32-.7.7-.7h53.16m0-1H1.7c-.94,0-1.7.76-1.7,1.7v31.53c0,.94.76,1.7,1.7,1.7h53.16c.94,0,1.7-.76,1.7-1.7v-31.53c0-.94-.76-1.7-1.7-1.7h0Z"></path> </g> <path class="cls-2-ic-use-disabled" d="m1.77,15.31c-1.08,0-1.58.84-1.58,1.5v31.59c0,.66.5,1.5,1.58,1.5h6.49V15.31H1.77Z"></path> </g> <circle class="cls-2-ic-use-disabled" cx="52.34" cy="19.19" r="19.19"></circle> </g> <polygon class="cls-1-ic-use-disabled" points="48.46 27.07 41.55 19.78 43.09 18.32 48.58 24.12 62.23 12.03 63.63 13.61 48.46 27.07"></polygon> </g> </g> </svg> <p style="font-weight: bold; font-size: 16px; margin-left: 6px; margin-top: 6px; color: rgb(180, 180, 180);">使う</p> </a> <a class="footer-item"> <svg id="_\u30EC\u30A4\u30E4\u30FC_2" data-name="\u30EC\u30A4\u30E4\u30FC 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.2 46.46" width="22" height="25"> <rect x="0" y="0" width="39" height="45" fill="none" stroke="red" stroke-width="1" stroke-dasharray="5" *ngIf="focusOn == 'inActiveColor'"></rect> <defs> <style> .cls-1-ic-profile-disabled {
                  fill: var(--inactive-color);
                } </style> </defs> <g id="text"> <g> <path class="cls-1-ic-profile-disabled" d="m29.13,11.27c0,6.22-5.04,11.27-11.27,11.27s-11.27-5.04-11.27-11.27S11.64,0,17.86,0s11.27,5.04,11.27,11.27Z"></path> <path class="cls-1-ic-profile-disabled" d="m37.2,44.02c0,1.34-1.01,2.44-2.24,2.44H2.24c-1.23,0-2.24-1.1-2.24-2.44v-10.36c0-1.34.91-2.91,2.03-3.48l14.55-4.7c1.11-.57,2.94-.57,4.05,0l14.55,4.7c1.11.57,2.03,2.14,2.03,3.48v10.36Z"></path> </g> </g> </svg> <p style="font-weight: bold; font-size: 16px; margin-left: 0px; margin-top: 5px; color: rgb(180, 180, 180);"></p> </a> </div> </div> </div> </div> 